
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { getPayerTree } from '../resources/api-constants'
import '../assets/styles/breadcrumb.scss'


interface BreadcrumbProps {
    payerData: any;
    billingData: string
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ payerData, billingData }) => {

    const loginVal = useSelector((state: any) => state.loginValues);

    let sid = ''
    const loginInfo = localStorage.getItem('_login_info')

    if (loginVal.token !== null) {
        sid = loginVal.sid
    }

    const userDetails = useSelector((state: any) => state.userDetails);
    const liveElements = useSelector((state: any) => state.liveElements);
    const hxElements = useSelector((state: any) => state.hxElements);
    const forumValues = useSelector((state: any) => state.forumValues);

    const urls = useSelector((state: any) => state.urlValues);

    const [payerUrl, setPayerUrl] = useState("")
    // const [billingUrl, setBillingUrl] = useState("")
    // const [specialityUrl, setSpecialityUrl] = useState("")

    // useEffect(() => {
    //     const userDetails = useSelector((state: any) => state.userDetails);
    // }, [userDetails])

    useEffect(() => {
       
        const fetchData = async () => {
            if (payerData !== '') {
                setPayerUrl(await getPayerTreeDetails(payerData))
                
            }
            // if (userDetails.speciality !== '' ) {
            //     setSpecialityUrl(await getPayerTreeDetails(userDetails.speciality))
            // }
            // if (billingData !== '') {
            //     setBillingUrl(await getPayerTreeDetails(billingData))
            // }
        };

        fetchData();
        
    }, [payerData])

    const getPayerTreeDetails = async (payerData: any) => {
       
        const data = {
            forums: [userDetails.speciality],
            element: payerData,
            username: userDetails.username
        }
        const res = await getPayerTree(data);
        let make_url = ''
        if (res !== 'error' && res.payers.length !== 0) {

            
            if (sid === '') {
                make_url = urls.forum + 'viewforum.php?f='+res.payers[0].forum_id+'&sid='+loginVal.sid;
            } else {
                make_url = urls.forum + 'viewforum.php?f='+res.payers[0].forum_id+'&sid='+sid;
            }
            
            // setForumUrl(make_url)
        }
        return make_url;
    }

    // const goToForumPage = () => {
    //     if (sid === '') {
    //         window.location.href = urls.forum + 'viewforum.php?f='+forum_id+'&sid='+loginVal.sid;
    //     } else {
    //         window.location.href = urls.forum + 'viewforum.php?f='+forum_id+'&sid='+sid;
    //     }
    // }
    
    return (
        <div className='breadcrumb-container'>

            <div className='hx-breadcrumb'>
                <span className='live-label'>LIVE -</span>
                &nbsp;
                <a className='lables-non-url'>{ userDetails.speciality }&nbsp;
                    <span className='count'>{ liveElements.specialitypostcount }</span>
                </a>
                { payerData !== '' && (
                    <>
                        &nbsp;/&nbsp;
                        <a href={payerUrl} className='lables'>{ payerData !== null ? payerData: '' }&nbsp;
                            <span className='count'>{ liveElements.payerpostcount }</span>
                            
                        </a>
                    </>
                )}
                
                { billingData !=='' && (
                    <>
                        &nbsp;/&nbsp;
                        <span className='lables-non-url'>{ billingData }&nbsp;
                            <span className='count'>{ liveElements.billingcodepostcount }</span>
                        </span>
                    </>
                )}
            </div>

            <div className='live-breadcrumb'>
                { billingData && (
                    <>
                        <span className='lables-non-url'>{ billingData }&nbsp;
                            <span className='count'>{ hxElements.BILLINGCODECOUNT ? hxElements.BILLINGCODECOUNT : 0 }</span>
                            &nbsp;/&nbsp;
                        </span>
                    </>
                )}
                
                { payerData && (
                    <>
                        <span className='lables-non-url'>{ payerData !== null ? payerData : '' }&nbsp;
                            <span className='count'>{ hxElements.PAYERCOUNT ? hxElements.PAYERCOUNT : 0 }</span>
                            &nbsp;/&nbsp;
                        </span>
                    </>
                )}
                <a className='lables-non-url'>{ userDetails.speciality }&nbsp;
                    <span className='count'>{ hxElements.SPECIALITYCOUNT }</span>
                </a>
                &nbsp;
                <span className='live-label'>- HX</span>
            </div>

        </div>
    )
}

export default Breadcrumb