import { createReducer } from '@reduxjs/toolkit';
import { setForumValues } from '../actions/forumValues';

interface ForumValuesReducer {
    DESC: string[];
    FORUM_DESC: string;
    FORUM_ID: number;
    FORUM_NAME: string;
    ICON_IMG_NAME: string;
    LIKES: number;
    NATIVE: number;
    POST: number;
    POSTS: number;
    REPLIES: number;
}

const initialState: ForumValuesReducer = {
    DESC: [],
    FORUM_DESC: '',
    FORUM_ID: 0,
    FORUM_NAME: '',
    ICON_IMG_NAME: '',
    LIKES: 0,
    NATIVE: 0,
    POST: 0,
    POSTS: 0,
    REPLIES: 0,
};

const ForumValuesReducer = createReducer<ForumValuesReducer>(initialState, (builder) => {
    builder.addCase(setForumValues, (state, action) => {
        state.DESC = action.payload.DESC;
        state.FORUM_DESC = action.payload.FORUM_DESC;
        state.FORUM_ID = action.payload.FORUM_ID;
        state.FORUM_NAME = action.payload.FORUM_NAME;
        state.LIKES = action.payload.LIKES;
        state.NATIVE = action.payload.NATIVE;
        state.POST = action.payload.POST;
        state.POSTS = action.payload.POSTS;
        state.REPLIES = action.payload.REPLIES;
    });
});

export default ForumValuesReducer;
