import React, { useEffect, useState, useRef } from 'react'
import Table from 'react-bootstrap/Table'
import { getInitialValues, getPatientClaimData, getToken, LoadLiveCommunityData, LoadHxCommunityData } from '../../resources/api-constants'
import { useSelector, useDispatch } from 'react-redux';
import { setLoginValues } from '../../store/actions/loginValues';
import { setUserDetails } from '../../store/actions/userDetails';
import '../../assets/styles/claim/claimElements.scss'
import { Flex } from 'antd';

interface ClaimPageProps {
    getLiveClaimValues: (newForum: any) => void;
    getHxClaimValues: (newForum: any) => void;
    getHeightData: (newForum: any) => void;
    updatePageNumber: (newForum: any) => void;
    getScrollVal1: (newForum: any) => void;
    limit: any;
    offset: any;
    pageNumber: any;
    scrollVal2: number;
}

const ClaimElements: React.FC <ClaimPageProps> = ({ getLiveClaimValues, getHxClaimValues, getHeightData, updatePageNumber, getScrollVal1, limit, offset, pageNumber, scrollVal2 }) => {

    interface Element {
        name: string;
        mrn: string;
        adm_date: string;
        D1: string;
        D2: string;
        D3: string;
        D4: string;
        cpt: string;
        pr_ins: string;
        pr_insurer_id: string;
        se_ins: string;
        se_insurer_id: string;
        tr_ins: string;
        tr_insurer_id: string;
        M1: string;
        M2: string;
        M3: string;
        M4: string;
        current_payer: string;
    }

    const dispatch = useDispatch();
    const userDetails = useSelector((state: any) => state.userDetails);

    const [elementData, setElementData] = useState<Element[]>([]);
    // const [currentPage, setCurrentPage] = useState(1);

    const [hxReqArrFinal, setHxReqArrFinal] = useState<{ [key: string]: string }[]>([]);
    const [liveReqArrFinal, setLiveReqArrFinal] = useState<{ [key: string]: string }[]>([]);

    const divRefs = useRef<(HTMLTableRowElement | null)[]>([]);
    const table1Ref = useRef<HTMLTableElement>(null);

    const [divHeights, setDivHeights] = useState<number[]>([]);

    useEffect(() => {
        // fetchToken();
        // fetchInitialValues();
        
        setTimeout(() => {
            userDetails.username !== '' && getClaimData(userDetails)
        }, 100);
        
    }, [userDetails, offset])

    useEffect(() => {
        setTimeout(() => {
            let heights = [];
            heights = divRefs.current.map(ref => ref?.clientHeight ?? 0);
            getHeightData(heights)
            setDivHeights(heights);
        }, 800);
    }, [divRefs, elementData])

    useEffect(() => {
        if (table1Ref.current !== null) {
            table1Ref.current.scrollTop = scrollVal2;
          }
    }, [scrollVal2])

    // const fetchToken = async() => {
    //     const res = await getToken({});
    //     if (res !== 'error') {
    //         dispatch(setLoginValues(res));
    //         localStorage.setItem('_login_info', JSON.stringify(res));
    //         fetchInitialValues();
    //     }
    // }

    // const fetchInitialValues = async () => {
    //     const login_info = localStorage.getItem('_login_info');
    //     if (login_info) {

    //         const params = {}
    //         const res = await getInitialValues(params);
    //         dispatch(setUserDetails(res));
    //         getClaimData(res);
            
    //     }
    // }

    const handleScroll = (e: any) => {
        if (scrollVal2 === 0){
            getScrollVal1(e.target.scrollTop)
        }
    }

    const getClaimData = async (res: any) => {

        const param = {
            user: res.userid,
            username: res.username,
            offset: offset,
            limit: limit
        }
        const resp = await getPatientClaimData(param);
        // console.log(resp.data);
        updatePageNumber(resp.data.length)
        setElementData(resp.data);
        const respData = resp.data;
        
        const liveReqArr: any = [];
        const hxReqArr: any = [];
        let i = 0;

        respData && respData.forEach((element: any) => {

            const liveparams: { payer: string, cpt: string } = { 
                payer: "", 
                cpt: "" 
            };
            const hxparamsMod: {
                PAYOR_ID: string,
                PR_INS: string,
                PR_INS_FKEY: number,
                SE_INS: string,
                SE_INS_FKEY: number,
                TE_INS: string,
                TE_INS_FKEY: number,
                [key: string]: string | number;
            } = {
                PAYOR_ID: '',
                PR_INS: '',
                PR_INS_FKEY: 0,
                SE_INS: '',
                SE_INS_FKEY: 0,
                TE_INS: '',
                TE_INS_FKEY: 0,
            }

            const cur_payer_ins = element.current_payer + '_INS';
            const cur_ins_id = respData[i]["current_payer"] + "_insurer_id";
            const cur_payer_fkey = respData[i]["current_payer"] + "_INS_FKEY";

            liveparams.payer = (respData[i] as any)[cur_payer_ins.toLowerCase()] || "";
            liveparams.cpt = element.cpt || "";

            hxparamsMod.PAYOR_ID = respData[i][cur_ins_id.toLowerCase()] != "N/A" && typeof respData[i][cur_ins_id.toLowerCase()] !== "undefined" ? respData[i][cur_ins_id.toLowerCase()] : "";
            hxparamsMod[cur_payer_ins] = respData[i][cur_payer_ins.toLowerCase()] || "";
            hxparamsMod[cur_payer_fkey] = respData[i][cur_payer_fkey.toLowerCase()] || 0;
            hxparamsMod.RECID = userDetails.ps_claim_recid;
            hxparamsMod.ROW_NUM = i + 1;
            hxparamsMod.USER_ID = Number(userDetails.userid);
            hxparamsMod.PAGE_INDEX = pageNumber;
            hxparamsMod.PRACTICE_CODE = userDetails.ps_account;
            // hxparamsMod.PRACTICE_CODE = '2117';
            hxparamsMod.PATIENT_FKEY = respData[i]["patient_pkey"] ? Number(respData[i]["patient_pkey"]) : 0;
            hxparamsMod.MEDICAL_RECORD_NUM = respData[i]["mrn"] ? respData[i]["mrn"] : "";
            hxparamsMod.AGE_FROM = 0;
            hxparamsMod.AGE_TO = 0;
            hxparamsMod.GENDER = "";
            hxparamsMod.DOS_FROM = respData[i]["dos_from"] ? respData[i]["dos_from"] : "";
            hxparamsMod.SERVICE_LOCATION_FKEY = respData[i]["service_location_fkey"] ? Number(respData[i]["service_location_fkey"]) : 0;
            hxparamsMod.CASE_NUM = respData[i]["case_no"] ? respData[i]["case_no"] : "";
            hxparamsMod.CASE_TYPE = respData[i]["case_type"] ? respData[i]["case_type"] : "";
            hxparamsMod.CLAIM_TYPE = respData[i]["claim_type"] ? respData[i]["claim_type"] : "";
            hxparamsMod.CLAIM_NUM = "";
            hxparamsMod.ENCOUNTER_LINE_FKEY = respData[i]["encounter_line_pkey"] ? Number(respData[i]["encounter_line_pkey"]) : 0;
            hxparamsMod.CPT_CODE = respData[i]["cpt"] ? respData[i]["cpt"] : "";
            hxparamsMod.TOTAL_CHARGE = respData[i]["charges"] ? Number(respData[i]["charges"]) : 0;
            hxparamsMod.DIAGNOSIS1 = "";
            hxparamsMod.DIAGNOSIS2 = "";
            hxparamsMod.DIAGNOSIS3 = "";
            hxparamsMod.DIAGNOSIS4 = "";
            hxparamsMod.MODIFIER1 = "";
            hxparamsMod.MODIFIER2 = "";
            hxparamsMod.MODIFIER3 = "";
            hxparamsMod.MODIFIER4 = "";
            hxparamsMod.LINE_STATUS = respData[i]["line_status"] ? respData[i]["line_status"] : "";
            hxparamsMod.LINE_RP = respData[i]["line_level_provider"] ? Number(respData[i]["line_level_provider"]) : 0;

            liveReqArr[i] = liveparams;
            hxReqArr[i] = hxparamsMod;
            i++;
        });

        setHxReqArrFinal(hxReqArr);
        setLiveReqArrFinal(liveReqArr)

        getLiveClaimValues(JSON.stringify(liveReqArr));
        getHxClaimValues(JSON.stringify(hxReqArr));
    }

    const claimchange = async (cpt: string, value : string, row_num : number, variable : string, current_payer : string, insure_id : string, current_payer_type : string, event: any) => {

        if (variable === "payer") {
            const updateHead = elementData;
            updateHead[row_num].current_payer = current_payer_type;
            setElementData(updateHead);
        }

        row_num = Number(row_num);
        const hxRequest: { [row_num: number]: { [variable: string]: string } } = {};
        const liveRequest: { [row_num: number]: { [variable: string]: string } } = {};
        const updatedHxArray = [...hxReqArrFinal];
        const updatedLiveArray = [...liveReqArrFinal];

        if (typeof event === 'undefined' || (typeof event !== 'undefined' && event.target.checked)) {
            
            if (updatedHxArray[row_num]) {
                updatedHxArray[row_num][variable] = value;
            }
        } else {
            
            if (updatedHxArray[row_num]) {
                updatedHxArray[row_num][variable] = '';
            }
        }

        if (variable === "payer") {
            insure_id = insure_id !== "" ? insure_id : "";
            hxRequest[row_num] = {}
            hxRequest[row_num]["PAYOR_ID"] = insure_id;
            //live data call only for change in payer value
            liveRequest[row_num] = {}
            liveRequest[row_num][variable] = value;

            if (updatedLiveArray[row_num]) {
                updatedLiveArray[row_num][variable] = value;
            }
            if (updatedHxArray[row_num]) {
                updatedHxArray[row_num]['PAYOR_ID'] = value;
            }
        }
        setHxReqArrFinal(updatedHxArray);
        setLiveReqArrFinal(updatedLiveArray);

        getLiveClaimValues(JSON.stringify(liveReqArrFinal));
        getHxClaimValues(JSON.stringify(hxReqArrFinal));

    }

    return (
        <div className="elements-container">
            <div className="element-content-container" ref={table1Ref} onScroll={handleScroll}>
                <Table striped bordered hover className='custom-table'>
                    <thead className='table-head'>

                        <tr>
                            <th className='th-orange-1'>Patient</th>
                            <th className='th-orange-1' colSpan={3}>Claim Header</th>
                            <th className='th-orange-1' colSpan={14}>Claim Lines</th>
                        </tr>

                        <tr>
                            <th className='th-orange'>Name</th>
                            <th className='th-orange'>MR#</th>
                            <th className='th-orange'>Auth</th>
                            <th className='th-orange'>Adm Date</th>
                            <th className='th-orange'>MRR</th>
                            <th className='th-orange'>D1</th>
                            <th className='th-orange'>D2</th>
                            <th className='th-orange'>D3</th>
                            <th className='th-orange'>D4</th>
                            <th className='th-orange'>CPT</th>
                            <th className='th-orange '>

                                <div className='th-payer'>
                                    Payer
                                </div>
                                
                                <div className='inner-th'>
                                    <div className=' th-right'>P</div>
                                    <div className=' th-right'>S</div>
                                    <div className='th-right-without-bdr'>T</div>
                                </div>

                            </th>
                            <th className='th-orange'>M1</th>
                            <th className='th-orange'>M2</th>
                            <th className='th-orange'>M3</th>
                            <th className='th-orange'>M4</th>
                        </tr>
                        
                    </thead>

                    <tbody>

                        {elementData && elementData.map((item, index) => (
                            <tr key={index} ref={ref => (divRefs.current[index] = ref as HTMLTableRowElement)}>
                                <td className='td-element'>{item.name}</td>
                                <td className='td-element'>{item.mrn}</td>
                                <td className='td-element'>&nbsp;</td>
                                <td className='td-element'>{item.adm_date}</td>
                                <td className='td-element'>&nbsp;</td>
                                <td className='td-element' >
                                    {item.D1 && ( 
                                        <label className='horizontal-layout'>
                                            <input className='chk-in-td' type="checkbox" style={{marginLeft: '4px', marginRight: '2px'}}
                                                onClick={(e) => claimchange(item.cpt, item.D1, index, 'DIAGNOSIS1', '', '', '', e)}>
                                            </input> 
                                            {item.D1}
                                        </label>
                                    )}
                                    
                                </td>
                                <td className='td-element' >
                                    {item.D2 && ( 
                                        <label className='horizontal-layout'>
                                            <input className='chk-in-td' type="checkbox" style={{marginLeft: '4px', marginRight: '2px'}}
                                                onClick={(e) => claimchange(item.cpt, item.D2, index, 'DIAGNOSIS2', '', '', '', e)}>
                                            </input> 
                                            {item.D2}
                                        </label>
                                    )}
    
                                </td>
                                <td className='td-element' >
                                    {item.D3 && ( 
                                        <label className='horizontal-layout'>
                                            <input className='chk-in-td' type="checkbox" style={{marginLeft: '4px', marginRight: '2px'}}
                                                onClick={(e) => claimchange(item.cpt, item.D3, index, 'DIAGNOSIS3', '', '', '', e)}>
                                            </input> 
                                            {item.D3}
                                        </label>
                                    )}
                                    
                                </td>
                                <td className='td-element' >
                                    {item.D4 && ( 
                                        <label className='horizontal-layout'>
                                            <input className='chk-in-td' type="checkbox" style={{marginLeft: '4px', marginRight: '2px'}}
                                                onClick={(e) => claimchange(item.cpt, item.D4, index, 'DIAGNOSIS4', '', '', '', e)}>
                                            </input> 
                                            {item.D4}
                                        </label>
                                    )}
                                    
                                </td>
                                <td className='td-element'>{item.cpt}</td>
                                <td className='td-element'>
                                    <div className='inner-th'>
                                        <div className=' th-right'>
                                            { item.pr_ins && (
                                            <label>
                                                <input type="radio" className='radio-in-td' 
                                                onChange={(e) => claimchange(item.cpt, item.pr_ins, index, 'payer', item.current_payer, item.pr_insurer_id, 'PR', e)} 
                                                name={`payerType_${index}`} id="" checked={item.current_payer === 'PR'}/>
                                                {item.pr_ins}
                                            </label>)}
                                        </div>
                                        <div className=' th-right'>
                                            { item.se_ins && (
                                            <label>
                                                <input type="radio" className='radio-in-td' 
                                                onChange={(e) => claimchange(item.cpt, item.se_ins, index, 'payer', item.current_payer, item.se_insurer_id, 'SE', e)} 
                                                name={`payerType_${index}`} id="" checked={item.current_payer === 'SE'}/>
                                                {item.se_ins}
                                            </label>)}
                                        </div>
                                        <div className='th-right-without-bdr'>
                                            { item.tr_ins && (
                                            <label>
                                                <input type="radio" className='radio-in-td' 
                                                onChange={(e) => claimchange(item.cpt, item.tr_ins, index, 'payer', item.current_payer, item.tr_insurer_id, 'TR', e)} 
                                                name={`payerType_${index}`} id="" checked={item.current_payer === 'TR'}/>
                                                {item.tr_ins}
                                            </label>)}
                                        </div>
                                    </div>
                                </td>
                                <td className='td-element' >
                                    { item.M1 && (
                                        <label className='horizontal-layout'>
                                            <input className='chk-in-td' type="checkbox" style={{marginLeft: '4px', marginRight: '2px'}}
                                            onClick={(e) => claimchange(item.cpt, item.M1, index, 'MODIFIER1', '', '', '', e)}>
                                            </input>
                                            {item.M1}
                                        </label>
                                    )}
                                    
                                </td>
                                <td className='td-element' >
                                    { item.M2 && (
                                        <label className='horizontal-layout'>
                                            <input className='chk-in-td' type="checkbox" style={{marginLeft: '4px', marginRight: '2px'}}
                                            onClick={(e) => claimchange(item.cpt, item.M2, index, 'MODIFIER2', '', '', '', e)}>
                                            </input>
                                            {item.M2}
                                        </label>
                                    )}
                                    
                                </td>
                                <td className='td-element' >
                                    { item.M3 && (
                                        <label className='horizontal-layout'>
                                            <input className='chk-in-td' type="checkbox" style={{marginLeft: '4px', marginRight: '2px'}}
                                            onClick={(e) => claimchange(item.cpt, item.M3, index, 'MODIFIER3', '', '', '', e)}>
                                            </input>
                                            {item.M3}
                                        </label>
                                    )}
                                    
                                </td>
                                <td className='td-element' >
                                    { item.M4 && (
                                        <label className='horizontal-layout'>
                                            <input className='chk-in-td' type="checkbox" style={{marginLeft: '4px', marginRight: '2px'}}
                                            onClick={(e) => claimchange(item.cpt, item.M4, index, 'MODIFIER4', '', '', '', e)}>
                                            </input>
                                            {item.M4}
                                        </label>
                                    )}
                                    
                                </td>

                            </tr>
                        ))}

                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default ClaimElements
