import { createReducer } from '@reduxjs/toolkit';
import { setLiveElements } from '../actions/liveElements';

interface LiveElementsReducer {
    forum_id: number; 
    specialitypostcount: number; 
    payerpostcount: number; 
    billingcodepostcount: number; 
}

const initialState: LiveElementsReducer = {
    forum_id: 0,
    specialitypostcount: 0,
    payerpostcount: 0,
    billingcodepostcount: 0
};

const liveElementsReducer = createReducer<LiveElementsReducer>(initialState, (builder) => {
    builder.addCase(setLiveElements, (state, action) => {
        state.forum_id = action.payload.forum_id;
        state.specialitypostcount = action.payload.specialitypostcount;
        state.payerpostcount = action.payload.payerpostcount;
        state.billingcodepostcount = action.payload.billingcodepostcount;
    });
});

export default liveElementsReducer;
