import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMicrochip, faRobot, faWaveSquare, } from '@fortawesome/free-solid-svg-icons'
import { askAI } from '../../../../resources/api-constants'
import { Button } from 'react-bootstrap';
import { DNA } from 'react-loader-spinner';
import '../../../../assets/styles/intelligence.scss'

interface ArtificialIntelligenceProps {
    paramData: any
}

const AiData: React.FC<ArtificialIntelligenceProps> = ({ paramData }) => {

    const [aiRes, setAiRes] = useState('')
    const [loader, setLoader] = useState(false)

    useEffect(() => {

        // const allValuesPresent = Object.values(paramData).every(value => value !== '');
        
        if (paramData !== '' && paramData.payer !== '' && paramData.billingCode !== '' && paramData.reason.length !== 0) {
            setLoader(true)
            getAiResponse();
        }

    }, [paramData])

    const getAiResponse = async () => {

        const reasons: string[] = []
        paramData.reason.forEach((element: any) => {
            reasons.push(element.label)
        });
        
        const req = {
            cpt: paramData.billingCode,
            payer: paramData.payer.label,
            reasons: reasons.join(', '),
            // age: paramData.patAge,
            // gender: paramData.patGender == 'M' ? "Male" : "Female"
        }

        const res = await askAI(req);
        // console.log(res);
        setLoader(false)
        setAiRes(res.DATA)
    }

    return (
        <div className="artificial-container">

            <div className="art-header">
                <h6 className="art-heading">
                    <FontAwesomeIcon icon={faMicrochip} className="notify-icon" />
                    Artificial Intelligence
                </h6>
            </div>

            <div className='ai-container'>
                <div className='suggetion-head'>
                    <span className='suggetion-label'>
                        <FontAwesomeIcon icon={faWaveSquare} className="notify-icon" />
                        AI Generated Suggetions.
                    </span>
                </div>

                {/* <div className='generate-btn-container'>
                    
                    <Button variant="outline-info" className='col-3 generate-btn' onClick={generateAiResponse}>
                        <FontAwesomeIcon icon={faWaveSquare} className="notify-icon" />
                        Ask AI
                    </Button>
                </div> */}

                {/* {err && (
                    <div className='error-container'>
                        <span className='err-label'>
                            Give me more Info!
                        </span>
                    </div>
                )} */}

                

                {loader ? (

                    <div className='ai-loader'>
                        <DNA
                            visible={true}
                            //   height="96"
                            width="96"
                            //   color="grey"
                            // strokeWidth="5"
                            // animationDuration="0.75"
                            ariaLabel="rotating-lines-loading"
                            //   wrapperStyle={{}}
                            //   wrapperClass=""
                        />
                    </div>

                ) : (

                    <div className='suggetion-body'>
                        <p className={ aiRes && 'ai-response'}>
                            {aiRes.split('\n').map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}
                        </p>
                    </div>
                )}

            </div>

        </div>
    )
}

export default AiData