import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage
import data from './data'
import userDetailsReducer from './userDetails';
import urlValuesReducer from './urlValues';
import TotalClaimsReducer from './totalClaims';
import liveElementsReducer from './liveElements';
import hxElementsReducer from './hxElements';
import ForumValuesReducer from './forumValues';
import loginValuesReducer from './loginValues';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

const rootReducer = combineReducers({
    data,
    userDetails: userDetailsReducer,
    liveElements: liveElementsReducer,
    hxElements: hxElementsReducer,
    forumValues: ForumValuesReducer,
    loginValues: loginValuesReducer,
    urlValues: urlValuesReducer,
    totalClaims: TotalClaimsReducer,
})

const persistedReducer = persistReducer(
    {
        key: 'root',
        storage,
        whitelist: ['data', 'userDetails', 'liveElements', 'hxElements', 'forumValues', 'loginValues', 'urlValues', 'totalClaims']
    },
    rootReducer
)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        })
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const persistor = persistStore(store)
