import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { promises } from "dns";

// const apiUrl = "http://127.0.0.1/" // local host
const apiUrl = "https://stageforum.paid.md/" // local server
const jwtApiArray = ['notificationcount', 'getcheckpoints', 'getelements', 'getpostcount', 'createpost', 'getpayertree', 'getbillingcodetree', 'hxpostcount.php', 'loadpatientclaimdata', 'loadlivecommunityintelligencedata', 'getcountpatientclaimdata', 'notifications']

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // console.log(response);
    return response;
}, function (error) {
    if (error.code !== 'ECONNABORTED') {
        localStorage.removeItem('_login_info');
        localStorage.clear();
        window.location.href = '/'
    }
    return "error";
});

axios.interceptors.request.use(async function (config) {
    const login_info = localStorage.getItem('_login_info');
    const jwtToken = login_info ? JSON.parse(login_info).token : '';
    // let tkn = ''
    if (config.url) {
        const pathSegments = config.url.split('/'); // Split URL by '/'
        const lastSegment = pathSegments[pathSegments.length - 1]; 
        if (jwtApiArray.includes(lastSegment)) {
            // try {
            //     const userResponse = await axios.post(
            //         apiUrl + 'gettoken', 
            //         {}
            //     );
            //     const r = validateResponse(userResponse);
            //     tkn = r.token
            // } catch (error) {
            //     console.error('Error fetching data:', error);
            // }
            // config.headers.Authorization = tkn === '' ? jwtToken : tkn;
            config.headers.Authorization = jwtToken;
            config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
        // } 
        // else if (lastSegment === 'postpsclaimdata') {
        //     config.headers['Content-Type'] = 'application/json';
        } else {
            config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
            config.withCredentials = true;
        }
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

const validateResponse = <T>(res: AxiosResponse<T>): T | string => {

    if (res.status === 200) {
        return res.data;
    } else if (res.status === 401) {
        localStorage.removeItem('_login_info');
        window.location.href = '/';
        return '';
    } else {
        return "error";
    }
};

// const cookies: string[] = document.cookie.split("; ");
// const filteredCookies: string[] = cookies.filter(cookie => cookie.startsWith('PHPSESSID'));

export const userLogin = async (data: any): Promise<any> => {
    try {
        const userResponse = await axios.post(apiUrl + 'loginsubmit', data);
        return validateResponse(userResponse);
    } catch (error) {
        console.error('Error fetching data:', error);
        // throw error;
    }
}

// export const userLogin = async (data: any): Promise<any> => {
//     try {
//         const userResponse = await axios.post(apiUrl + 'api/v1/login', data);
//         return validateResponse(userResponse);
//     } catch (error) {
//         console.error('Error fetching data:', error);
//         // throw error;
//     }
// }

export const getNotificationCount = async (data: any): Promise<any> => {
    try {
        const userResponse = await axios.post(apiUrl + 'api/v1/notificationcount', data,);
        return validateResponse(userResponse);
    } catch (error) {
        console.error('Error fetching data:', error);
        // throw error;
    }
};

export const getInitialValues = async (data: any): Promise<any> => {
    try {
        const userResponse = await axios.post(
                apiUrl + 'getinitialvalues', 
                data
            );
        return validateResponse(userResponse);
    } catch (error) {
        console.error('Error fetching data:', error);
        // throw error;
    }
};

// export const getInitialValues = async (data: any): Promise<any> => {
//     try {
//         const userResponse = await axios.post(apiUrl + 'api/v1/getinitialvalues', data, { headers, withCredentials:true });
//         return validateResponse(userResponse);
//     } catch (error) {
//         console.error('Error fetching data:', error);
//         // throw error;
//     }
// };

export const getCheckpointsData = async (data: any): Promise<any> => {
    try {
        const userResponse = await axios.post(apiUrl + 'api/v1/getcheckpoints', data);
        return validateResponse(userResponse);
    } catch (error) {
        console.error('Error fetching data:', error);
        // throw error;
    }
};

export const getElementsData = async (data: any): Promise<any> => {
    try {
        const userResponse = await axios.post(apiUrl + 'api/v1/getelements', data);
        return validateResponse(userResponse);
    } catch (error) {
        console.error('Error fetching data:', error);
        // throw error;
    }
};

export const fetchPayersData = async (data: any): Promise<any> => {
    try {
        // const cookie = filteredCookies.length > 0 ? filteredCookies[0] : '';
        const userResponse = await axios.post(
            apiUrl + 'getpayers', 
            data
        );
        return validateResponse(userResponse);
    } catch (error) {
        console.error('Error fetching data:', error);
        // throw error;
    }
};

export const getPostCountData = async (data: any): Promise<any> => {
    try {
        const userResponse = await axios.post(apiUrl + 'api/v1/getpostcount', data);
        return validateResponse(userResponse);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const createPost = async (data: any): Promise<any> => {
    try {
        const userResponse = await axios.post(apiUrl + 'api/v1/createpost', data);
        return validateResponse(userResponse);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const getBillingCodes = async (data: any): Promise<any> => {
    try {
        const userResponse = await axios.post(
            apiUrl + 'getbillingcodes', 
            data
        );
        return validateResponse(userResponse);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const getToken = async (data: any): Promise<any> => {
    try {
        const userResponse = await axios.post(
            apiUrl + 'gettoken', 
            data
        );
        return validateResponse(userResponse);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const getPayerTree = async (data: any): Promise<any> => {
    try {
        const userResponse = await axios.post(apiUrl + 'api/v1/getpayertree', data);
        return validateResponse(userResponse);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const getBillingCodeTree = async (data: any): Promise<any> => {
    try {
        const userResponse = await axios.post(apiUrl + 'api/v1/getbillingcodetree', data);
        return validateResponse(userResponse);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const getHxCountData = async (data: any): Promise<any> => {
    try {
        const userResponse = await axios.post(apiUrl + 'api/v1/hxdata/hxpostcount.php', data);
        return validateResponse(userResponse);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const logout = async (data: any): Promise<any> => {
    try {
        const userResponse = await axios.post(
            apiUrl + 'logout', 
            data
        );
        return validateResponse(userResponse);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const askAI = async (data: any): Promise<any> => {
    try {
        const userResponse = await axios.post(
            apiUrl + 'askAi', 
            data
        );
        return validateResponse(userResponse);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const getPatientClaimData = async (data: any): Promise<any> => {
    try {
        const userResponse = await axios.post(apiUrl + 'api/v1/loadpatientclaimdata', data);
        return validateResponse(userResponse);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const callFromPS = async (data: any): Promise<any> => {
    try {
        const userResponse = await axios.post(
            apiUrl + 'postpsclaimdata', 
            data
        );
        return validateResponse(userResponse);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const LoadLiveCommunityData = async (data: any): Promise<any> => {
    try {
        const userResponse = await axios.post(apiUrl + 'api/v1/loadlivecommunityintelligencedata', data);
        return validateResponse(userResponse);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const LoadHxCommunityData = async (data: any): Promise<any> => {
    try {
        const userResponse = await axios.post(apiUrl + 'api/v1/hxdata/hxpostcount.php', data);
        return validateResponse(userResponse);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const getTokenWithKey = async (data: any): Promise<any> => {
    try {
        const userResponse = await axios.post(apiUrl + 'api/v1/gettokenbyid', data);
        return validateResponse(userResponse);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const getClaimDataCount = async (data: any): Promise<any> => {
    try {
        const userResponse = await axios.post(apiUrl + 'api/v1/getcountpatientclaimdata', data);
        return validateResponse(userResponse);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const getNotificationData = async (data: any): Promise<any> => {
    try {
        const userResponse = await axios.post(apiUrl + 'api/v1/notifications', data);
        return validateResponse(userResponse);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}