import React from 'react'
import Form from 'react-bootstrap/Form';
import '../../assets/styles/claim/claimHead.scss'

const ClaimElements: React.FC = () => {

    

    return (
        <div className='head-container'>

            <div className='content-container'>
            
                <div className='input-container dos-container'>
                    <p className='label dos-label'>DOS</p>
                    <Form.Control className='inputs' type="text" placeholder="" />
                </div>

                <div className='input-container legal-container'>
                    <p className='label legal-label'>Legal Entity</p>
                    <Form.Control className='inputs' type="text" placeholder="" />
                </div>

                <div className='input-container show-container'>
                    <p className='label show-label'>SHOW</p>
                    <Form.Check inline label="Errors Only" value="error" name="show" type="radio" id="inline-radio-error" className='label'/>
                    <Form.Check inline label="All Claims" value="all" name="show" type="radio" id="inline-radio-all" className='label'/>
                </div>

                <div className='input-container payer-container'>
                    <p className='label payer-label'>PAYER TYPE</p>
                    <Form.Control className='inputs' type="text" placeholder="" />
                </div>

            </div>
            
        </div>
    )
}

export default ClaimElements