import { createReducer } from '@reduxjs/toolkit';
import { setTotalClaims } from '../actions/totalClaims';

interface TotalClaimsReducer {
    totalClaims: number; 
}

const initialState: TotalClaimsReducer = {
    totalClaims: 0,
};

const totalClaimsReducer = createReducer<TotalClaimsReducer>(initialState, (builder) => {
    builder.addCase(setTotalClaims, (state, action) => {
        state.totalClaims = action.payload.totalClaims;
    });
});

export default totalClaimsReducer;