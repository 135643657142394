import React, { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesLeft, faAnglesRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import ClaimHead from '../../components/claim/ClaimHead'
import ClaimElements from '../../components/claim/ClaimElements'
import ClaimValues from '../../components/claim/ClaimValues'
import { getToken, getTokenWithKey, getClaimDataCount } from '../../resources/api-constants'
import { useSelector, useDispatch } from 'react-redux';
import { setLoginValues } from '../../store/actions/loginValues';
import { setUserDetails } from '../../store/actions/userDetails';
import { setTotalClaims } from '../../store/actions/totalClaims';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../assets/styles/claim/claim.scss'

const Claim: React.FC = () => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const idKeyValue = searchParams.get('idkey');
    // const userNameValue = searchParams.get('username');

    const dispatch = useDispatch();
    const userDetails = useSelector((state: any) => state.userDetails);
    const loginValues = useSelector((state: any) => state.loginValues);
    const claimCount = useSelector((state: any) => state.totalClaims);
    const navigate = useNavigate();

    const [claimLiveData, setClaimLiveData] = useState()
    const [claimHxData, setClaimHxData] = useState()
    const [heightData, setHeightData] = useState()
    const [containerHeight, setContainerHeight] = useState(0)
    const [scrollVal2, setScrollVal2] = useState(0)
    const [scrollVal1, setScrollVal1] = useState(0)

    const [pageNumber, setPageNumber] = useState(1);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(20);
    const [totalPages, setTotalPages] = useState(claimCount.totalClaims !== 0 ? (Math.ceil((claimCount.totalClaims) / 20)) : 0)
    const [totalRecords, setTotalRecords] = useState(claimCount.totalClaims !== 0 ? claimCount.totalClaims : 0)

    const divRef = useRef(null);

    useEffect(() => {
        // fetchToken()
        fetchTokenWithIdKey();
    }, []);

    useEffect(() => {
        const observer = new ResizeObserver(entries => {
            for (const entry of entries) {
                // Update height state with the new height value
                setContainerHeight(entry.contentRect.height);
            }
        });
      
        // Start observing the target div
        if (divRef.current) {
            observer.observe(divRef.current);
        }
      
        // Clean up observer on component unmount
        return () => {
            if (divRef.current) {
                observer.unobserve(divRef.current);
            }
        };
    }, [])

    const fetchTokenWithIdKey = async() => {
        const param = {
            idKey: idKeyValue,
            // username: userNameValue
        }
        const res = await getTokenWithKey(param);
        if (res !== 'error' && 'token' in res) {
            if (res.token !== undefined) { 
                localStorage.setItem('_login_info', JSON.stringify(res));
                dispatch(setLoginValues(res))
            }

            if (claimCount.totalClaims === 0) {
                getClaimCount(res.userid, res.username);
            } else {
                getClaimCount(userDetails.userid, userDetails.username);
            }

            if (userDetails.ps_claim_recid === undefined || userDetails.ps_claim_recid === '') {
                // console.log(res)
                // dispatch(setUserDetails({
                //     username: res.username,
                //     ps_account: res.ps_account,
                //     ps_claim_recid: res.ps_claim_recid,
                //     speciality: res.speciality,
                //     userid: res.userid
                // }));
                dispatch(setUserDetails(res));

            } else if (userDetails.ps_claim_recid !== res.ps_claim_recid){
                dispatch(setUserDetails(res));
            }

        } else {
            // navigate('/')
        }
        // console.log(res);
    }

    const getClaimCount = async (userid: number, username: string) => {
        const req = {
            user: userid,
            username: username
        }
        const res = await getClaimDataCount(req);
        if (res !== 'error') {
            // console.log(Math.ceil((res.data) / 20))
            // setTotalRecords(res.data)
            dispatch(setTotalClaims({totalClaims: res.data}));
            setTotalPages(Math.ceil((res.data) / 20));
        }
    }

    const handleClaimLiveParams = (newData: any) => {
        setClaimLiveData(newData);
    }

    const handleClaimHxParams = (newData: any) => {
        setClaimHxData(newData);
    }

    const handleHeightCalc = (newData: any) => {
        // console.log(newData);
        setHeightData(newData);
    }

    const handlePageNumber = (newData: any) => {
        // console.log(Math.ceil(newData / 20));
        // setPageNumber(Math.ceil(newData / 20))
    }

    const handleFirst = () => {
        setPageNumber(1);
        setOffset(0)
        // onPageChange(1);
    };

    const handlePrevious = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
            setOffset(offset - 20)
            // onPageChange(pageNumber - 1);
        }
    };

    const handleNext = () => {
        if (pageNumber < totalPages) {
            setPageNumber(pageNumber + 1);
            setOffset(offset + 20)
            // onPageChange(pageNumber + 1);
        }
    };

    const handleLast = () => {
        setPageNumber(totalPages);

        if (totalRecords > 20) {
            setOffset((((Math.ceil(totalRecords / 20)) * 10) * 2) - 20)
        } else {
            setOffset(0)
        }
        // onPageChange(totalPages); totalRecords
    };

    const handleScrollVal2 = (newData: number) => {
        setScrollVal2(newData)
    }

    const handleScrollVal1 = (newData: number) => {
        setScrollVal1(newData)
    }

    return (
        <div className='claim-container'>

            <ClaimHead/>

            <div className='claim-data-container' ref={divRef} >

                <ClaimElements getLiveClaimValues={handleClaimLiveParams} getHxClaimValues={handleClaimHxParams} getHeightData={handleHeightCalc} updatePageNumber={handlePageNumber} getScrollVal1={handleScrollVal1} limit={limit} offset={offset} pageNumber={pageNumber} scrollVal2={scrollVal2}/>
                <ClaimValues claimLiveData={claimLiveData} claimHxData={claimHxData} heightData={heightData} containerHeight={containerHeight} scrollVal1={scrollVal1} getScrollVal2={handleScrollVal2} />

            </div>

            <div className='pagination-container'>
                <button className={`page-btn-first ${pageNumber === 1 ? 'disable-btn' : ''}`} onClick={handleFirst} disabled={pageNumber === 1}>
                    <FontAwesomeIcon icon={faAnglesLeft} />
                </button>
                <button className={`page-btn ${pageNumber === 1 ? 'disable-btn' : ''}`} onClick={handlePrevious} disabled={pageNumber === 1}>
                    <FontAwesomeIcon icon={faAngleLeft} />
                </button>
                <div className='count-container' >
                    Page <span className='page-count'>{pageNumber}</span> of <span style={{marginLeft: '8px', fontSize: '15px'}}>{totalPages}</span>
                </div>
                <button className={`page-btn ${pageNumber === totalPages ? 'disable-btn' : ''}`} onClick={handleNext} disabled={pageNumber === totalPages}>
                    <FontAwesomeIcon icon={faAngleRight} />
                </button>
                <button className={`page-btn-last ${pageNumber === totalPages ? 'disable-btn' : ''}`} onClick={handleLast} disabled={pageNumber === totalPages}>
                    <FontAwesomeIcon icon={faAnglesRight} />
                </button>
            </div>

        </div>
    )
}

export default Claim