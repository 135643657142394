import { createReducer } from '@reduxjs/toolkit';
import { setUserDetails } from '../actions/userDetails';

interface UserDetailsReducer {
    username: string;
    ps_account: string;
    ps_claim_recid: string; 
    speciality: string; 
    userid: number; 
}

const initialState: UserDetailsReducer = {
    username: '',
    ps_account: '',
    ps_claim_recid: '',
    speciality: '',
    userid: 0
};

const userDetailsReducer = createReducer<UserDetailsReducer>(initialState, (builder) => {
    builder.addCase(setUserDetails, (state, action) => {
        state.username = action.payload.username;
        state.ps_account = action.payload.ps_account;
        state.ps_claim_recid = action.payload.ps_claim_recid;
        state.speciality = action.payload.speciality;
        state.userid = action.payload.userid;
    });
});

export default userDetailsReducer;
