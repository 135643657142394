import { createReducer } from '@reduxjs/toolkit';
import { setHxElements } from '../actions/hxElements';

interface HxElementsReducer {
    SPECIALITYCOUNT: number; 
    PAYERCOUNT: number | null; 
    BILLINGCODECOUNT: number | null; 
    // forum_id: number; 
}

const initialState: HxElementsReducer = {
    // forum_id: 0,
    SPECIALITYCOUNT: 0,
    PAYERCOUNT: 0,
    BILLINGCODECOUNT: 0
};

const hxElementsReducer = createReducer<HxElementsReducer>(initialState, (builder) => {
    builder.addCase(setHxElements, (state, action) => {
        // state.forum_id = action.payload.forum_id;
        state.SPECIALITYCOUNT = action.payload.SPECIALITYCOUNT;
        state.PAYERCOUNT = action.payload.PAYERCOUNT;
        state.BILLINGCODECOUNT = action.payload.BILLINGCODECOUNT;
    });
});

export default hxElementsReducer;
