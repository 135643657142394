import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import LiveIcon from '../../assets/images/live_icon.svg'
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faXmark, faPencil, faComment, faThumbsUp, faCircle } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { setLoginValues } from '../../store/actions/loginValues';
import { getPayerTree, getBillingCodeTree, getToken } from '../../resources/api-constants'
import { useNavigate } from 'react-router-dom';
import '../../assets/styles/breadcrumb.scss'
import '../../assets/styles/elementtree.scss'

interface PayerTree {
    payer_name: string;
    total_post_count: string;
}

interface BillingCode {
    code: string;
    id: string;
    count: string;
    likes: number;
    post: number;
    replies: number;
}

const ElementTree: React.FC = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loginVal = useSelector((state: any) => state.loginValues);

    let sid = ''
    const loginInfo = localStorage.getItem('_login_info')

    if (loginVal.token !== null) {
        sid = loginVal.sid
    }

    useEffect(() => {
        !loginInfo && navigate('/')
        getPayerTreeDetails()
        fetchToken()
        // console.log(location.state);
        // console.log(userDetails);
    }, [])

    const liveElements = useSelector((state: any) => state.liveElements);
    const userDetails = useSelector((state: any) => state.userDetails);
    const forumValues = useSelector((state: any) => state.forumValues);
    const urls = useSelector((state: any) => state.urlValues);

    const [showHide, setShowHide] = useState(false)
    const [showHideIcon, setShowHideIcon] = useState(false)
    // const [colapseVal, setColapseVal] = useState("+")
    const [payerValue, setPayerValue] = useState('')
    const [billingValue, setBillingValue] = useState('')
    const [payerTree, setPayerTree] = useState<PayerTree[]>([]);
    const [openCloseCollapse, setOpenCloseCollapse] = useState('')
    const [billingCodeTreeVal, setBillingCodeTreeVal] = useState<BillingCode[]>([]);

    const fetchToken = async() => {
        const res = await getToken({});
        if (res !== 'error') {
            dispatch(setLoginValues(res))
            localStorage.setItem('_login_info', JSON.stringify(res));
        }
        // console.log(res);
    }

    const getPayerTreeDetails = async () => {
       
        const data = {
            forums: [userDetails.speciality],
            element: forumValues.FORUM_NAME,
            username: userDetails.username
        }
        const res = await getPayerTree(data);

        if (res !== 'error') {
            setPayerTree(res.payers)
        }
    }

    const HandleShowHideSearch = () => {
        setShowHide(!showHide)
        setShowHideIcon(!showHideIcon)
    }

    const HandleColapse = (payer: any) => {
        if (openCloseCollapse == payer.payer_name) {
            setOpenCloseCollapse('')
        } else {
            getBillingCodeTreeData(payer)
        }
    }

    const getBillingCodeTreeData = async(payerVal: any) => {

        const req = {
            forums: [userDetails.speciality],
            element: forumValues.FORUM_NAME,
            payer: payerVal.payer_name,
            username: userDetails.username
        }
        const resp = await getBillingCodeTree(req);
        if (resp !== 'error') {
            setBillingCodeTreeVal(resp.billing_codes)
            // colapseVal === '+' ? setColapseVal("-") : setColapseVal("+")
            setOpenCloseCollapse(payerVal.payer_name)
            
        }
    }

    const handlePayerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPayerValue(event.target.value)
    }

    const handlePayerInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        // console.log(event.target.value);
        setPayerValue(event.target.value)
        if (event.target.value === '' && billingValue === '') {
            getPayerTreeDetails()
        }
        
    }

    const handleBillingInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        // console.log(event.target.value);
        setBillingValue(event.target.value)
        if (event.target.value === '' && payerValue === '') {
            getPayerTreeDetails()
        }
    }

    const handleBillingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBillingValue(event.target.value)
    }

    const getElementTree = (): void => {
        if (payerValue !== '' || billingValue !== '') {
            
            const reqObj = {
                forums: [userDetails.speciality],
                element: forumValues.FORUM_NAME,
                username: userDetails.username
            }
            if (payerValue !== '') {
                (reqObj as any)['payer_search'] = payerValue;
            }
            if (billingValue !== '') {
                (reqObj as any)['code_search'] = billingValue
            }
            getPayerTreeData(reqObj)
            
        }
        
    }

    const getPayerTreeData = async (data: any) => {
        const res = await getPayerTree(data)
        if (res !== 'error') {
            setPayerTree(res.payers)
        }
    }

    const forumNavigate = (forum_id: string) => {
        if (sid === '') {
            window.location.href = urls.forum + 'viewforum.php?f='+forum_id+'&from=element&sid='+loginVal.sid;
        } else {
            window.location.href = urls.forum + 'viewforum.php?f='+forum_id+'&from=element&sid='+sid;
        }
    }

    return (
        <>
            {loginInfo && (
                <>
                    <Navbar/>

                    <div className='breadcrumb-container'>

                        <div className='hx-breadcrumb'>
                            <a href="/home" className='lables'>
                                <span className='live-label'>LIVE -</span>
                            </a>
                            &nbsp;
                            <a className='lables-non-url'>{ userDetails.speciality }&nbsp;
                                <span className='count'>{ liveElements.specialitypostcount }</span>
                            </a>
                            &nbsp;/&nbsp;
                            <a className='lables-non-url'>{ forumValues.FORUM_NAME }&nbsp;
                                <span className='count'>{ forumValues.POSTS }</span>
                            </a>
                        </div>

                    </div>

                    <div className='element-container'>

                        <div className='container-body'>
                            <div className='forum-details'>
                                
                                <div className="live-icon-outer">
                                    <div className="live-icon-inner">
                                        <img src={LiveIcon} alt="paid.md logo" style={{ padding: '0px', marginLeft: "-5px" }} />
                                    </div>
                                </div>

                                <div className='forum-inner'>
                                    <div className='forum-head'>
                                        { forumValues.FORUM_NAME }
                                    </div>
                                    <hr />
                                    <div className='forum-body'>
                                        { forumValues.DESC.map((des: any, index: number) => (
                                            <p className='reason-details' key={index}>
                                                { forumValues.FORUM_NAME.split(', ')[index] }{' - '}{ des }
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <button className='search-icon' onClick={HandleShowHideSearch}>
                                {showHideIcon ? (
                                    <FontAwesomeIcon icon={faXmark} style={{color:"#009dff", backgroundColor: "white"}}/>
                                ) : (
                                    <FontAwesomeIcon icon={faMagnifyingGlass} style={{color:"#009dff", backgroundColor: "white"}}/>
                                )}
                            </button>

                            {showHide && (
                                <div className='billing-code-search'>
                                    <div className='search-container'>
                                        <div className='payer-det'>
                                            <p className='elem-input-label'>Payer</p>
                                            <Form.Control className='elem-input' required type="text" placeholder="" value={payerValue} onChange={handlePayerChange} onInput={handlePayerInput} />
                                        </div>
                                        <div className='billing-det'>
                                            <p className='elem-input-label'>Billing code</p>
                                            <Form.Control className='elem-input' required type="text" placeholder="" value={billingValue} onChange={handleBillingChange} onInput={handleBillingInput}/>
                                        </div>
                                        <div className='search-btn'>
                                            <Button variant="success" type="submit" className='col-6 elem-btn' onClick={getElementTree}>Search</Button>
                                        </div>
                                    </div>
                                </div> 
                            )}

                            <hr style={{marginTop: "35px"}} />

                                {payerTree && payerTree.map((value: any, index: number) => (
                                    <div className='payer-details' key={index}>
                                        <div className='payer-det-head'>
                                            <div className='colapse-head' onClick={() => {HandleColapse(value)}}>
                                                <h2 className='open-close'>{ openCloseCollapse === value.payer_name ? '-' : '+' }</h2>&nbsp;&nbsp;
                                                <p style={{marginTop: "10px", width: "700px"}}>{ value.payer_name }</p>
                                                <div className='count-wrapper'>
                                                    <div className='colapse-count'>
                                                        { value.total_post_count }
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        {openCloseCollapse === value.payer_name &&(
                                            <>
                                                {billingCodeTreeVal && billingCodeTreeVal.map((billingCode: BillingCode, index: number) => (
                                                    <div key={index} className='billing-code-container'>
                                                        <a className='billing-code-code' href="#" onClick={() => {forumNavigate(billingCode.id)}}>
                                                            <FontAwesomeIcon icon={faCircle} style={{ marginRight: '3px' }} />
                                                            {billingCode.code}
                                                        </a>
                                                        <div className='billing-code-det'>
                                                            <div style={{marginRight: "12px"}}> 
                                                                <small>
                                                                    <FontAwesomeIcon icon={faPencil} style={{ marginRight: '3px' }} />
                                                                </small>
                                                                Posts : {billingCode.post} 
                                                            </div>
                                                            <div style={{marginRight: "12px"}}> 
                                                                <small>
                                                                    <FontAwesomeIcon icon={faComment} style={{ marginRight: '3px' }} />
                                                                </small>
                                                                Replies : {billingCode.replies}
                                                            </div>
                                                            <div style={{marginRight: "12px"}}> 
                                                                <small>
                                                                    <FontAwesomeIcon icon={faThumbsUp} style={{ marginRight: '3px' }} />
                                                                </small>
                                                                Likes : {billingCode.likes} 
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </div>
                                ))}
                        </div>
                        
                    </div>
                </>
            )}
        </>
    )
}

export default ElementTree
