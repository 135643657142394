import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Editor, { ContentEditableEvent } from 'react-simple-wysiwyg';
import Select from 'react-select';
// import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import { getCheckpointsData, getElementsData, fetchPayersData, createPost, getBillingCodes } from '../../resources/api-constants'
import { useSelector } from 'react-redux';
import '../../assets/styles/form.scss'
import { log } from 'console';

interface FormPageProps {
    onPayerDataChange: (newData: any) => void;
    onReasonChange: (newReason: any) => void;
    onBillingCodeChange: (newBillingCode: any) => void;
    onForumDataChange: (newForumData: any) => void;
    onHxParamDataChange: (newHxParam: any) => void;
    onAiParamDataChange: (newAiParam: any) => void;
    onForumCreation: (newForum: boolean) => void;
}

interface SelectOption {
    label: string;
    value: string;
}

interface CheckPoint {
    Code: string;
    Value: string;
    Selected: boolean;
}

interface InsuranceDataType {
    name: string;
    payer_id: string;
    pkey: string;
    // Add other properties as needed
}

interface hxParam {
    payerPkey: string;
    payerId: string;
    patAge: string;
    patGender: string;
    billingCode: string;
}

interface aiParam {
    payerPkey: string;
    payerId: string;
    patAge: string;
    payer: string[];
    patGender: string;
    billingCode: string;
    reason: string[];
    subject: string;
    desc: string;
}

const PostForm: React.FC <FormPageProps> = ({ onPayerDataChange, onReasonChange, onBillingCodeChange, onForumDataChange, onHxParamDataChange, onAiParamDataChange, onForumCreation }) => {
    const navigate = useNavigate();

    const userDetails = useSelector((state: any) => state.userDetails);
    const urls = useSelector((state: any) => state.urlValues);
    const loginVal = useSelector((state: any) => state.loginValues);

    const animatedComponents = makeAnimated();

    const [checkPoints, setCheckPoints] = useState<CheckPoint[]>()
    const [validated, setValidated] = useState(false);
    const [editorValue, setEditorValue] = useState('');
    const [editorError, setEditorError] = useState(false);
    const [billingCodeError, setBillingCodeError] = useState(false)
    const [selectedClaimType, setSelectedClaimType] = useState('HCFA');
    const [selectedBillingCode, setSelectedBillingCode] = useState('CPT');
    const [billingCode, setBillingCode] = useState('')
    const [selectedGender, setSelectedGender] = useState('');
    const [billingCodeLabel, setBillingCodeLabel] = useState('CPT')
    const [reasonValue, setReasonValue] = useState<SelectOption | null>(null);
    const [reasonArrValue, setReasonArrValue] = useState<string[] | null>(null);
    const [subContent, setSubContent] = useState('')
    const [hxParamData, setHxParamData] = useState<hxParam>({
        payerPkey: '',
        payerId: '',
        patAge: '',
        patGender: '',
        billingCode: ''
    });
    const [aiParamData, setAiParamData] = useState<aiParam>({
        payerPkey: '',
        payerId: '',
        patAge: '',
        payer: [],
        patGender: '',
        billingCode: '',
        reason: [],
        subject: '',
        desc: ''
    });
    const [insuranceData, setInsuranceData] = useState<InsuranceDataType[] | undefined>();
    const [payerValue, setPayerValue] = useState<SelectOption>({
        label: '',
        value: ''
    });
    const [billingCodeValue, setBillingCodeValue] = useState<SelectOption>({
        label: '',
        value: ''
    });
    const [payerError, setPayerError] = useState(false);
    const [reasonError, setReasonError] = useState(false);

    let sid = ''
    const login_info = localStorage.getItem('_login_info')

    if (login_info !== null) {
        sid = JSON.parse(login_info).sid
    } 

    interface ElementData {
        value: string;
        // description: string;
        label: string;
    }
      
    interface ResponseData {
        ubcielements: ElementData[];
    }

    interface PayerResponseData {
        payerElements: ElementData[];
    }

    interface BillingResponseData {
        billing_codes: ElementData[];
    }

    // Initialize with an empty array
    const [transformedData, setTransformedData] = useState<ResponseData>({
        ubcielements: [],
    });

    // Initialize with an empty array
    const [transformedPayerData, setTransformedPayerData] = useState<PayerResponseData>({
        payerElements: [],
    });

    const [transformedBillingData, setTransformedBillingData] = useState<BillingResponseData>({
        billing_codes: [],
    });

    useEffect(() => {
        if (userDetails.username !== '') {
            fetchCheckPointsData()
            onForumDataChange([userDetails.speciality])
        }
    }, [userDetails])

    useEffect(() => {
        checkPoints !== undefined && fetchElements()
    }, [checkPoints, selectedClaimType, selectedBillingCode])

    useEffect(() => {
        
        if ((billingCodeValue === null || (billingCodeValue !== null && billingCodeValue.label === "")) && (payerValue === null || (payerValue !== null && payerValue.label === ""))) {
            // console.log("both");
            // console.log(billingCodeValue);
            // console.log(payerValue);
            // console.log("both");
            const updatedHxParamData = { ...hxParamData, payerId: "", payerPkey: "", billingCode: "" };
            onHxParamDataChange(updatedHxParamData)

            onForumDataChange([userDetails.speciality])
            onPayerDataChange('')
            onBillingCodeChange("")
        } else if ((billingCodeValue === null || (billingCodeValue !== null && billingCodeValue.label === "")) && !(payerValue === null || (payerValue !== null && payerValue.label === ""))) {
            // console.log("billingCodeValue");
            // console.log(billingCodeValue);
            // console.log(payerValue.label);
            // console.log("billingCodeValue");
            const updatedHxParamData = { ...hxParamData, billingCode: "" };
            onHxParamDataChange(updatedHxParamData)

            onForumDataChange([userDetails.speciality, payerValue.label])
            onBillingCodeChange("")
        } else if ((payerValue === null || (payerValue !== null && payerValue.label === "")) && !(billingCodeValue === null || (billingCodeValue !== null && billingCodeValue.label === ""))) {
            // console.log("payerValue");
            // console.log(payerValue);
            // console.log(billingCodeValue.label);
            // console.log("payerValue");
            // onForumDataChange([userDetails.speciality,billingCodeValue.label])
            const updatedHxParamData = { ...hxParamData, payerId: "", payerPkey: ""};
            onHxParamDataChange(updatedHxParamData)

            onPayerDataChange('')
        }

    }, [billingCodeValue, payerValue])

    const fetchCheckPointsData = async () => {
        const data = {
            username: userDetails.username,
        }
        const resp = await getCheckpointsData(data);
        if (resp !== "error") {
            setCheckPoints(resp.checkpoints)
            // onPayerDataChange(userDetails.speciality)
        }
    }

    const fetchElements = async () => {

        const reqData = {
            checkpoints: [] as string[],
            claimtype: selectedClaimType,
            username: userDetails.username,
            categorylevel: selectedBillingCode === 'Payer Alert' ? selectedBillingCode : ''
        }
        if (checkPoints !== undefined){
            checkPoints.forEach((checkpoint) => {
                reqData.checkpoints.push(checkpoint.Code);
            });
        }
        const resp = await getElementsData(reqData)
        
        // Transform the data
        setTransformedData({
            ubcielements: resp.ubcielements.map((element: any) => ({
                value: element.name,
                // label: `${element.name} - ${element.description}`,
                label: `${element.description}`,
                //   description: element.description,
            })),
        });
        
    }

    const handleClaimTypeChange = (e: ContentEditableEvent): void => {
        setSelectedClaimType(e.target.value)
        e.target.value === 'HCFA' ? setBillingCodeLabel("CPT") : setBillingCodeLabel("HCPCS")
        setTransformedData({
            ubcielements: []
        });
    };

    const handleElementCategoryChange = (e: ContentEditableEvent): void => {
        setSelectedBillingCode(e.target.value)
        if (e.target.value === 'Payer Alert') {
            setBillingCode("Payer Alert")
            onBillingCodeChange("Payer Alert")
            setBillingCodeError(false)
            if (payerValue !== null) {
                payerValue.label !== '' && onForumDataChange([userDetails.speciality, payerValue.label, 'Payer Alert'])
            }
        } else {
            setBillingCode("")
        }
    };

    const handleBillingCodeChange = (newValue: any, actionMeta: { action: string }) => {
        if (actionMeta.action === 'input-change') {
            if (newValue.length > 2) {
                getBillingCodeData(newValue)
                // getAllPayerData(newValue)
            }

        } 
    };

    const handleBillingCodeSelect = (selectedValue: any): void => {
        
        if (selectedValue !== null) {
            setBillingCode(selectedValue.value)
            onBillingCodeChange(selectedValue.value)
            setBillingCodeError(false)
            if (payerValue !== null) {
                payerValue.label !== '' && onForumDataChange([userDetails.speciality, payerValue.label, selectedValue.value])
            }
            const updatedHxParamData = { ...hxParamData, billingCode: selectedValue.value };
            const updatedAiParamData = { ...aiParamData, billingCode: selectedValue.value };
            setHxParamData(updatedHxParamData);
            setAiParamData(updatedAiParamData)
            onHxParamDataChange(updatedHxParamData)
            onAiParamDataChange(updatedAiParamData)
        }
        setBillingCodeValue(selectedValue)
    }

    const getBillingCodeData = async(param: string) => {
        const req = {
            code_name: param
        }
        const resp = await getBillingCodes(req)
        if (resp !== "error") {
            setTransformedBillingData({
                billing_codes: resp.billing_codes.map((element: any) => ({
                    // label: element.code_desc,
                    label: element.billing_code,
                    value: element.billing_code,
                })),
            });
        }
    }

    const handleGenderChange = (e: ContentEditableEvent): void => {
        setSelectedGender(e.target.value)
        const updatedHxParamData = { ...hxParamData, patGender: e.target.value };
        const updatedAiParamData = { ...aiParamData, patGender: e.target.value };
        setHxParamData(updatedHxParamData);
        setAiParamData(updatedAiParamData)
        onHxParamDataChange(updatedHxParamData)
        onAiParamDataChange(updatedAiParamData)
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        
        const form = event.currentTarget;
        const formData = new FormData(form);
        let reason;
        if (selectedClaimType === 'HCFA'){
            reason = reasonArrValue && reasonArrValue.join(", ")
        } else {
            reason = formData.get('reason')
        }

        if (form.checkValidity() === false || editorValue === '' || reason === null || reason === '' || payerValue.label === '' || billingCode === '') {
            editorValue === '' ? setEditorError(true) : setEditorError(false)
            reason === null || reason === '' && setReasonError(true)
            payerValue.label === '' && setPayerError(true)
            billingCode === '' && setBillingCodeError(true)
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
        } else {
            onForumCreation(true)
            const forum_array = [userDetails.speciality, payerValue.label, billingCode, reason]
            const postParam = {
                // claimType: selectedClaimType,
                // payer: payerValue.label,
                // billingCode: selectedBillingCode,
                // age: formData.get('age'),
                // gender: formData.get('gender'),
                // diagnosis: {
                //     d1: formData.get('d1'),
                //     d2: formData.get('d2'),
                //     d3: formData.get('d3'),
                //     d4: formData.get('d4')
                // },
                // reason: reasonArrValue && reasonArrValue.join(", "),
                forums: forum_array,
                post: editorValue,
                subject: formData.get('subject'),
                username: userDetails.username,
                
            }
            createNewPost(postParam)
            // console.log(postParam);
            event.preventDefault();
            event.stopPropagation();
            // setValidated(true);
        }
    };

    const createNewPost = async(postParam: object) => {
        try {
            const resp = await createPost(postParam)
            if (resp !== 'error') {
                onForumCreation(false)
                // console.log(resp);
                if (loginVal.sid === '') {
                    window.location.href = urls.forum + 'viewtopic.php?f='+resp.f+'&t='+resp.t+'&sid='+sid;
                } else {
                    window.location.href = urls.forum + 'viewtopic.php?f='+resp.f+'&t='+resp.t+'&sid='+loginVal.sid;
                }
                // navigate(`/forum/viewtopic.php?f=${resp.f}&t=${resp.t}&sid=${sid}`)
            } else {
                // console.log(resp);
                onForumCreation(false)
            }
        } catch (error) {
            onForumCreation(false)
            console.error('Error creating post:', error);
        }
    }

    const getAllPayerData = async (data: any) => {
        const param = {
            code_name: data
        }
        const resp = await fetchPayersData(param)
        setInsuranceData(resp.insurance_name)
        if (resp !== "error") {
            
            setTransformedPayerData({
                payerElements: resp.insurance_name.map((element: any) => ({
                    label: element.name,
                    value: element.pkey,
                    //   description: element.description,
                })),
            });
            
        }
    }

    const handlePayerInputChange = (newValue: any, actionMeta: { action: string }) => {
        if (actionMeta.action === 'input-change') {
            // Action when input changes
            // console.log('Input Value:', newValue);
            // onPayerDataChange(newValue)
            if (newValue.length > 2) {
                getAllPayerData(newValue)
            }

        } else if (actionMeta.action === 'select-option') {
          // Action when an option is selected
        //   console.log('Selected Option:', newValue);
        } else if (actionMeta.action === 'clear') {
          // Action when the selection is cleared
        //   console.log('Selection cleared');
        }
    };

    const handlePayerSelect = (selectedValue: any): void => {
        // console.log(selectedValue);
        if (selectedValue !== null) {
            // insuranceData && insuranceData.forEach(item => console.log("item.payer_id:", item.payer_id));
            const filteredArray = insuranceData && selectedValue && insuranceData.filter((item: any) => String(item.pkey) === String(selectedValue.value));
            // console.log(filteredArray[0].payer_id);
            const updatedHxParamData = { ...hxParamData, payerPkey: selectedValue.value, payerId: filteredArray[0].payer_id };
            const updatedAiParamData = { ...aiParamData, payerPkey: selectedValue.value, payerId: filteredArray[0].payer_id, payer: selectedValue };
            setHxParamData(updatedHxParamData);
            setAiParamData(updatedAiParamData);
            onHxParamDataChange(updatedHxParamData)
            onAiParamDataChange(updatedAiParamData)

            onPayerDataChange(selectedValue !== null ? selectedValue.label : '' )
            billingCode === '' ? onForumDataChange([userDetails.speciality, selectedValue.label]) : onForumDataChange([userDetails.speciality, selectedValue.label, billingCode])
        }
        setPayerValue(selectedValue) 
        setPayerError(false)
    }

    const handleReasonChange = (selectedValue: any): void => {
        const newArray = selectedValue.map((item: SelectOption) => item.value);
        setReasonArrValue(newArray)
        onReasonChange(newArray)
        setReasonValue(selectedValue.value)
        const updatedAiParamData = { ...aiParamData, reason: selectedValue };
        setAiParamData(updatedAiParamData);
        onAiParamDataChange(updatedAiParamData)
        setReasonError(false)
    }

    function onEditorChange(e: ContentEditableEvent): void {
        setEditorValue(e.target.value);
        // const updatedAiParamData = { ...aiParamData, desc: e.target.value };
        // onAiParamDataChange(updatedAiParamData)
    }   

    const resetOtherValues = () => {
        window.location.reload()
        // setPayerValue({
        //     label: '',
        //     value: ''
        // })
        // setBillingCodeValue({
        //     label: '',
        //     value: ''
        // })
        // setReasonValue(null)
        // setEditorValue('')
    }

    const handleAgeChange = (event: ContentEditableEvent) => {
        const updatedHxParamData = { ...hxParamData, patAge: event.target.value };
        const updatedAiParamData = { ...aiParamData, patAge: event.target.value };
        setHxParamData(updatedHxParamData);
        setAiParamData(updatedAiParamData);
        onHxParamDataChange(updatedHxParamData)
        onAiParamDataChange(updatedAiParamData)
    };

    const handleSubBlur = () => {
        const updatedAiParamData = { ...aiParamData, subject: subContent };
        setAiParamData(updatedAiParamData);
        onAiParamDataChange(updatedAiParamData)
    }

    const handleDescBlur = () => {
        const updatedAiParamData = { ...aiParamData, desc: editorValue };
        setAiParamData(updatedAiParamData);
        onAiParamDataChange(updatedAiParamData)
    }

    return (
        <div className="form-container">
            <div className="home-header"></div>
            <div className="form-body">

                <Form className='bootstrap-form' validated={validated} onSubmit={handleSubmit} noValidate>

                    <div className='row claim-type-row'>
                        <Form.Group className="mb-4 " controlId="form-radio-claim-type">
                            <Form.Check inline label="HCFA" value="HCFA" name="claimType" type="radio" id="inline-radio-hcfa" checked={selectedClaimType === 'HCFA'} onChange={handleClaimTypeChange}/>
                            <Form.Check inline label="UB04" value="UB04" name="claimType" type="radio" id="inline-radio-ub04" checked={selectedClaimType === 'UB04'} onChange={handleClaimTypeChange}/>
                        </Form.Group>
                    </div>

                    <div className='row row-payer'>
                        <Form.Group className="mb-4 " controlId="formPayer">
                            <Form.Label>Payer <span className='required-field'>*</span></Form.Label>

                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                components={animatedComponents}
                                // defaultValue={payerValue}
                                value={payerValue}
                                isDisabled={false}
                                isClearable={true}
                                isSearchable={true}
                                
                                styles={{
                                    singleValue: (base) => ({
                                        ...base,
                                        padding: 5,
                                        borderRadius: 4,
                                        border: transformedPayerData['payerElements'].length !== 0 ? "1px solid black" : '',
                                        background: transformedPayerData['payerElements'].length !== 0 ? "#8a8a8a;" : "",
                                        color: 'white',
                                        display: 'flex',
                                    }),
                                }}

                                // isMulti
                                options={transformedPayerData['payerElements']}
                                onInputChange={handlePayerInputChange}
                                onChange={handlePayerSelect}
                            />

                            <span className='editor-error'>{payerError && 'Please provide a valid Payer.'}</span>
                        </Form.Group>
                    </div>

                    <div className='row row-element-category'>
                        <Form.Group className="mb-4" controlId="form-radio-element-category">
                            <Form.Check required inline label={billingCodeLabel} name="elementCategoryLevel" value={billingCodeLabel} type="radio" checked={selectedBillingCode === billingCodeLabel} onChange={handleElementCategoryChange} id="inline-radio-cpt"/>
                            <span className='required-field'>*</span>
                            &nbsp;&nbsp; / &nbsp;&nbsp;
                            <Form.Check required inline label="Payer Alert" name="elementCategoryLevel" value="Payer Alert" type="radio" checked={selectedBillingCode === 'Payer Alert'} onChange={handleElementCategoryChange} id="inline-radio-payer"/>
                            <span className='required-field'>*</span>
                        </Form.Group>
                    </div>

                    <div className='row row-subject'>
                        <Form.Group className="mb-4 " controlId="formBillingCode">
                            {billingCode !== 'Payer Alert' ? (
                                <>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        components={animatedComponents}
                                        // defaultValue={billingCodeValue}
                                        value={billingCodeValue}
                                        isDisabled={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        styles={{
                                            singleValue: (base) => ({
                                                ...base,
                                                padding: 5,
                                                borderRadius: 4,
                                                border: transformedBillingData['billing_codes'].length !== 0 ? "1px solid black" : '',
                                                background: transformedBillingData['billing_codes'].length !== 0 ? "#8a8a8a;" : "",
                                                color: 'white',
                                                display: 'flex',
                                            }),
                                        }}
                                        // isMulti
                                        options={transformedBillingData['billing_codes']}
                                        onInputChange={handleBillingCodeChange}
                                        onChange={handleBillingCodeSelect}
                                    />
                                    <span className='editor-error'>{billingCodeError && 'Please provide a valid Billing code.'}</span>
                                </>
                            ) : (
                                // <Form.Control required type="text" placeholder="" readOnly name="billingCode" value={billingCode}/>
                                <></>
                            )}  
                                
                            {/* <Form.Control.Feedback type="invalid">
                                Please provide a valid Subject.
                            </Form.Control.Feedback> */}
                        </Form.Group>
                    </div>

                    <div className='row row-age-gender'>
                        <Form.Group className="mb-4 col-6" controlId="formAge">
                            <Form.Label>Age <span className='required-field'>*</span></Form.Label>
                            <Form.Control required type="text" placeholder="" name='age' onChange={handleAgeChange}/>
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid Age.
                            </Form.Control.Feedback>
                        </Form.Group>
                        &nbsp;&nbsp;
                        <Form.Group className="mb-4 col-3" controlId="form-radio-claim-type">
                            <Form.Label>Gender <span className='required-field'>*</span></Form.Label><br/>
                            <div className='gender-chk'>
                                <Form.Check required inline label="Male" name="gender" type="radio" value="M" checked={selectedGender === 'M'} onChange={handleGenderChange} id="gender-radio-male"/>
                                <Form.Check required inline label="Female" name="gender" type="radio" value="F" checked={selectedGender === 'F'} onChange={handleGenderChange} id="gender-radio-female"/>
                            </div>
                            {/* selectedGender */} 
                        </Form.Group>
                    </div>
                    {/* <Form.Label>Diagnosis </Form.Label><br/>
                    <div className='row row-diagnosis'>
                        
                        <Form.Group className="mb-4 col-3" controlId="diagnosis-d1">
                            <Form.Control type="text" placeholder="D1" name='d1' />
                        </Form.Group>

                        <Form.Group className="mb-4 col-3" controlId="diagnosis-d2">
                            <Form.Control type="text" placeholder="D2" name='d2'/>
                        </Form.Group>

                        <Form.Group className="mb-4 col-3" controlId="diagnosis-d3">
                            <Form.Control type="text" placeholder="D3" name='d3'/>
                        </Form.Group>

                        <Form.Group className="mb-4 col-3" controlId="diagnosis-d4">
                            <Form.Control type="text" placeholder="D4" name='d4'/>
                        </Form.Group>
                    </div> */}

                    <div className='row row-reason'>
                        <Form.Group className="mb-4 " controlId="formReason">
                            <Form.Label>Reason for non payment <span className='required-field'>*</span></Form.Label>

                            {selectedClaimType === 'HCFA' ? (
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    components={animatedComponents}
                                    value={reasonValue}
                                    isDisabled={false}
                                    isClearable={true}
                                    isSearchable={true}
                                    styles={{
                                        multiValueLabel: (base) => ({
                                            ...base,
                                            padding: 5,
                                            borderRadius: 4,
                                            border: transformedData['ubcielements'].length !== 0 ? "1px solid black" : '',
                                            background: transformedData['ubcielements'].length !== 0 ? "#8a8a8a;" : "",
                                            color: 'white',
                                            display: 'flex',
                                        }),
                                    }}
                                    isMulti
                                    options={transformedData['ubcielements']}
                                    onChange={handleReasonChange}
                                />
                            ):(
                                <Form.Control required type="text" placeholder="" name="reason"/>
                            )}
                            <span className='editor-error'>{reasonError && 'Please provide a valid Reason.'}</span>
                        </Form.Group>
                    </div>

                    <div className='row row-subject'>
                        <Form.Group className="mb-4 " controlId="formSubject">
                            <Form.Label>Subject <span className='required-field'>*</span></Form.Label>
                            <Form.Control required type="text" placeholder="" name="subject" onBlur={handleSubBlur} onChange= { (e) => setSubContent(e.target.value) } />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid Subject.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>

                    <div className='row row-describe'>
                        <Form.Group className="mb-5 " controlId="formDescribe">
                            <Form.Label>Describe <span className='required-field'>*</span></Form.Label>
                            <Editor value={editorValue} onChange={onEditorChange} onBlur={handleDescBlur} containerProps={{ style: { height: '180px' } }}/>
                            <span className='editor-error'>{editorError && 'Please provide a valid Description.'}</span>
                        </Form.Group>
                    </div>

                    <div className='row row-buttons '>
                        <Button variant="success" type="submit" size='sm' className='col-6 seek-help-btn'>Seek Help</Button>

                        <Button variant="success" type="reset" size='sm' className='col-6 reset-btn' onClick={resetOtherValues}>Reset</Button>
                    </div>

                </Form>
                
            </div>
        </div>
    )
}

export default PostForm