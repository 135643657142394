import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { KeyOutlined, UnlockOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faUser } from '@fortawesome/free-solid-svg-icons'
import { Button, Form, Input, Space } from 'antd'
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png'
import { useDispatch, useSelector } from 'react-redux';
import { setLoginValues } from '../../store/actions/loginValues';
import { userLogin } from '../../resources/api-constants'
import styles from './styles.module.scss'

type FieldType = {
    username?: string
    password?: string
    account?: string
}

const LoginPage = () => {

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const loginInfo = localStorage.getItem('_login_info')

    const loginVal = useSelector((state: any) => state.loginValues);

    const [errorVisible, setErrorVisible] = useState(false)
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        account:'',
    });

    useEffect(() => {
        // Check if token is available in local storage
        if (loginInfo) {
          // Redirect to home page if token is present
          navigate('/home');
        }
      }, [navigate]);
    
    const handleSubmit = (formData:any) => {
        loginSubmit(formData)
    };

    const handleInputChange = (event:any) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    const loginSubmit = async (params: any[]) => {
        const resp = await userLogin(params);
        
        if (resp !== "error") {
            if (resp.CODE === 200) {
                
                dispatch(setLoginValues(resp.DATA))
                localStorage.setItem('_login_info', JSON.stringify(resp.DATA));
                
                if (localStorage.getItem('_login_info') !== null) {
                    navigate('/home');
                } 
                
            } else {
                setErrorVisible(true)
                setFormData({
                    username: '',
                    password: '',
                    account:'',
                })
            }
        }
    }
     
    
    return (
        <div className={`${styles.loginBackground} img-fluid`}>
            <Container className={`container  col-lg-3 `}>
                <div className={`${styles.wrapper} rounded-4`}>

                    <div style={{backgroundColor: "white"}}>

                        {/* Sign in  */}
                        <div className={`${styles.signIn} p-2 py-2 rounded-top-4`}>
                            <p className=" fw-normal h4 mx-3 mb-1 "> Sign In</p>
                        </div>

                        {/* paid.md Logo */}
                        <div className={`${styles.logo} d-flex justify-content-center pt-4 pb-4`}>
                            <img src={logo} alt="logo_img" />
                        </div>

                        {errorVisible && (<span className='login-error' style={{color: "red", paddingLeft: '37%', fontSize: "15px"}}>
                            User login failed !
                        </span>)}

                        {/*  Login Form starts */}
                        <Form 
                        name="normal_login" 
                        className={`${styles.loginform} p-4 rounded-bottom-4 `} 
                        layout="vertical" 
                        requiredMark="optional"
                        onFinish = {handleSubmit}>

                            <Space>
                                <FontAwesomeIcon icon={faUser} className={`${styles.nameBtn}`} />
                                <label className="h6 mt-2">Username</label>
                            </Space>

                            <Form.Item<FieldType> name="username" rules={[{ required: true, message: 'Please enter your Username' }]}>
                                <Input 
                                className="rounded-0 border-top-0 border-end-0 border-start-0  border-secondary-subtle"
                                value={formData.username}
                                onChange={handleInputChange} />
                            </Form.Item>

                            <Space>
                                <FontAwesomeIcon icon={faLock} className={styles.passBtn} />
                                <label className="h6 mt-2">Password</label>
                            </Space>

                            <Form.Item<FieldType> name="password" rules={[{ required: true, message: 'Please enter your Password' }]}>
                                <Input 
                                className="rounded-0 border-top-0 border-end-0 border-start-0 border-secondary-subtle"
                                type='password'
                                value={formData.password}
                                onChange={handleInputChange} />
                            </Form.Item>

                            <Space>
                                <KeyOutlined className={`${styles.accountBtn}`} />
                                <label className="h6 mt-2">Account</label>
                            </Space>

                            <Form.Item<FieldType> name="account" rules={[{ required: true, message: 'Please enter your Account Details' }]}>
                                <Input 
                                className="rounded-0 border-top-0 border-end-0 border-start-0 border-secondary-subtle"
                                value={formData.account}
                                onChange={handleInputChange} />
                            </Form.Item>

                            <Form.Item className="d-flex justify-content-center mb-0 mt-1 mb-1 ">
                                <Button
                                    type="primary"
                                    size="large"
                                    className={` ${styles.submitBtn} `}
                                    htmlType="submit"
                                    icon={<UnlockOutlined className="align-items-center" />}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Container>
        </div>
    )
}
export default LoginPage
