import { createReducer } from '@reduxjs/toolkit';
import { setUrlValues } from '../actions/urlValues';

interface UrlValuesReducer {
    api: string; 
    forum: string; 
}

const initialState: UrlValuesReducer = {
    api: 'https://stageforum.paid.md/',
    // api: 'http://127.0.0.1/',
    forum: 'https://stageforum.paid.md/forum/',
    // forum: 'http://127.0.0.1/forum/',
};

const urlValuesReducer = createReducer<UrlValuesReducer>(initialState, (builder) => {
    builder.addCase(setUrlValues, (state, action) => {
        state.api = action.payload.api;
        state.forum = action.payload.forum;

    });
});

export default urlValuesReducer;
