import React, {useState, useEffect, useRef} from 'react'
import Table from 'react-bootstrap/Table'
import { LoadLiveCommunityData, LoadHxCommunityData } from '../../resources/api-constants'
import { useSelector, useDispatch } from 'react-redux';
import { MagnifyingGlass } from 'react-loader-spinner';
import '../../assets/styles/claim/claimValues.scss'

interface ClaimElementsProps {
    claimLiveData: any
    claimHxData: any
    heightData: any
    containerHeight: number
    scrollVal1: any
    getScrollVal2: (newForum: any) => void;
}

const ClaimElements: React.FC<ClaimElementsProps> = ({ claimLiveData, claimHxData, heightData, containerHeight, scrollVal1, getScrollVal2 }) => {

    interface ValueData {
        CITY: string;
        STATE: string;
        COUNTRY: string;
      }

    interface ElementValues {
        Bundling: ValueData;
        withhold: ValueData;
        CHANCES_DENIEL: ValueData;
        Hx_Trouble_Maker_Claim: ValueData;
        Chances_You_will_not_get_Paid: ValueData;
        LAG_Time_since_DOS: ValueData;
        Days_to_UTF: ValueData;
        Hx_DSO_indicator: ValueData;
        Hx_Avg_Payment_Lag_Since_DOS: ValueData;
        Material_Payer_Update_this_FY: ValueData;
        Under_Payment: ValueData;
        Low_Reimbursement: ValueData;
        Out_of_Network_Payment: ValueData;
        Audit_Tracker: ValueData;
        Payer_Specific1: ValueData;
        MR_Request: ValueData;
        appeal_Percentage: ValueData;
        Appeal_and_Paid_Percentage: ValueData;
        National: ValueData;
        State: ValueData;
        Local: ValueData;
        Your_Practice: ValueData;
        Payment_Amt: ValueData;
        Expected_Date: ValueData;
        Under_Over_Payment: ValueData;
        Community_Contribution_was_helpful: ValueData;
        Community_Contribution_indicator: ValueData;
        Rated_by_Community: ValueData;
        All_Clear: ValueData;
        Low_reimbursement_Action: ValueData;
        Contract_Negoitation: ValueData;
        Build_this_as_front_end_validation: ValueData;
        Avg_Payment_Days_in_Community_since_DOS: ValueData;
    }

    const [elementValueData, setElementValueData] = useState<ElementValues[]>([]);
    const [showLoader, setShowLoader] = useState(true)
    const [isChrome, setIsChrome] = useState(false)
    const [isFirefox, setIsFirefox] = useState(false)
    const [isSafari, setIsSafari] = useState(false)

    const table2Ref = useRef<HTMLTableElement>(null);

    const userDetails = useSelector((state: any) => state.userDetails);

    useEffect(() => {
        // Check if the browser is Chrome
        const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        setIsChrome(isChrome)
        // Check if the browser is Firefox
        const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        setIsFirefox(isFirefox)
        // Check if the browser is Safari
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        setIsSafari(isSafari)
        
        if ( claimLiveData && claimHxData) {
            setElementValueData([])
            setShowLoader(true);
            loadLiveData(JSON.stringify(claimLiveData));
            loadHxData(JSON.stringify(claimHxData));
        }
    }, [claimLiveData, claimHxData])

    useEffect(() => {
        if (table2Ref.current !== null) {
            table2Ref.current.scrollTop = scrollVal1;
          }
    }, [scrollVal1])

    // useEffect(() => {
    //     console.log(heightData)
    // }, [heightData])

    const handleScroll = (e: any) => {
        if (scrollVal1 === 0) {
            getScrollVal2(e.target.scrollTop)
        }
    }

    const loadLiveData = async (liveRequestJson: string) => {
        const live_req = {
            username: userDetails.username,
            user: userDetails.userid,
            loc_speciality: userDetails.speciality,
            params: liveRequestJson
        }

        // const live_res = await LoadLiveCommunityData(live_req);
        
        // console.log(live_res);
        // setShowLoader(false);
    
    }

    const loadHxData = async (hxRequestJson: string) => {
        const hx_req = {
            username: userDetails.username,
            method: "claim",
            params: hxRequestJson
        }

        const hx_res = await LoadHxCommunityData(hx_req);
        const jsonArray = Object.values(hx_res);
        setElementValueData(jsonArray as ElementValues[]);
        setShowLoader(false);
        // console.log(jsonArray[0]);
        // jsonArray[0] && console.log(Object.keys(jsonArray[0]));
        
        // jsonArray.forEach((element: any) => {
        //     console.log(element);
            
        // });
        // console.log(JSON.stringify(hx_res));
        // hx_res.forEach((element: any) => {
        //     console.log(element);
        // });
    
    }

    return (
        <div className="values-container">
            <div className="table-container" ref={table2Ref} onScroll={handleScroll}>
                <Table striped bordered hover className='custom-table'>
                    <thead className='table-head'>
                        <tr>
                            <th className="th-outer th-bg-violet" colSpan={3}>
                                Troublemaker
                            </th>
                            <th className="th-outer th-bg-green" colSpan={6}>
                                Payment Lag
                            </th>
                            <th className="th-outer th-bg-lightblue" colSpan={7}>
                                Strengthen the Community! / Commuity Alert
                            </th>
                            <th className="th-outer th-bg-red" colSpan={5}>
                                Payment Metrics
                            </th>
                            <th className="th-outer th-bg-blue" colSpan={4}>
                                Payer Nuances
                            </th>
                            <th className="th-outer th-bg-red" colSpan={5}>
                                Denials
                            </th>
                            <th className="th-outer th-bg-darkyellow" colSpan={4}>
                                Reimbursements Metrics
                            </th>
                            <th className="th-outer th-bg-darkgreen" colSpan={3}>
                                Payment Forecast
                            </th>
                            <th className="th-outer th-bg-darkblue" colSpan={3}>
                                Community Performance
                            </th>
                            <th className="th-outer th-bg-green" colSpan={1}>
                                Submit
                            </th>
                            <th className="th-outer th-bg-orange" colSpan={3}>
                                Apply the Knowledge
                            </th>
                        </tr>

                        <tr>
                            <th className="th-all th-bg-violet1">
                                <div className="th-align-bt">Chances This will Get Denied</div>
                            </th>
                            <th className="th-bg-violet1">
                                <div className="th-align-bt">Hx Trouble Maker Claim</div>
                            </th>
                            <th className="th-bg-violet1">
                                <div className="th-align-bt">Chances You will not Get Paid</div>
                            </th>
                            <th className="th-bg-green1">
                                <div className="th-align-bt">LAG Time Since DOS</div>
                            </th>
                            <th className="th-bg-green1">
                                <div className="th-align-bt">Days to UTF</div>
                            </th>
                            <th className="th-bg-green1">
                                <div className="th-align-bt">Days to Response Limit Threshold</div>
                            </th>
                            <th className="th-bg-green1">
                                <div className="th-align-bt">Hx DSO Indicator</div>
                            </th>
                            <th className="th-bg-green1">
                                <div className="th-align-bt">Hx Avg Payment Lag Since DOS</div>
                            </th>
                            <th className="th-bg-green1">
                                <div className="th-align-bt">Avg Pmt Days in Community Since DOS</div>
                            </th>
                            <th className="th-bg-lightblue1">
                                <div className="th-align-bt">Denials</div>
                            </th>
                            <th className="th-bg-lightblue1">
                                <div className="th-align-bt">Low Reimbursement</div>
                            </th>
                            <th className="th-bg-lightblue1">
                                <div className="th-align-bt">Bundling</div>
                            </th>
                            <th className="th-bg-lightblue1">
                                <div className="th-align-bt">Payer Rating</div>
                            </th>
                            <th className="th-bg-lightblue1">
                                <div className="th-align-bt">MR Request</div>
                            </th>
                            <th className="th-bg-lightblue1">
                                <div className="th-align-bt">Appeal %</div>
                            </th>
                            <th className="th-bg-lightblue1">
                                <div className="th-align-bt">Appeal and Paid %</div>
                            </th>
                            <th className="th-bg-red1">
                                <div className="th-align-bt">Under Payment</div>
                            </th>
                            <th className="th-bg-red1">
                                <div className="th-align-bt">Withhold</div>
                            </th>
                            <th className="th-bg-red1">
                                <div className="th-align-bt">Low Reimbursement</div>
                            </th>
                            <th className="th-bg-red1">
                                <div className="th-align-bt">Out of Network Payment</div>
                            </th>
                            <th className="th-bg-red1">
                                <div className="th-align-bt">Audit Tracker</div>
                            </th>
                            <th className="th-bg-blue1">
                                <div className="th-align-bt">Material Payer Update this FY</div>
                            </th>
                            <th className="th-bg-blue1">
                                <div className="th-align-bt">Payer Specific</div>
                            </th>
                            <th className="th-bg-blue1">
                                <div className="th-align-bt">Payer Specific</div>
                            </th>
                            <th className="th-bg-blue1">
                                <div className="th-align-bt">Payer Specific</div>
                            </th>
                            <th className="th-bg-red1">
                                <div className="th-align-bt">Denials</div>
                            </th>
                            <th className="th-bg-red1">
                                <div className="th-align-bt">Bundling</div>
                            </th>
                            <th className="th-bg-red1">
                                <div className="th-align-bt">MR Request</div>
                            </th>
                            <th className="th-bg-red1">
                                <div className="th-align-bt">Appeal %</div>
                            </th>
                            <th className="th-bg-red1">
                                <div className="th-align-bt">Appeal and Paid %</div>
                            </th>
                            <th className="th-bg-darkyellow1">
                                <div className="th-align-bt">National</div>
                            </th>
                            <th className="th-bg-darkyellow1">
                                <div className="th-align-bt">State</div>
                            </th>
                            <th className="th-bg-darkyellow1">
                                <div className="th-align-bt">Local</div>
                            </th>
                            <th className="th-bg-darkyellow1">
                                <div className="th-align-bt">Your Practice</div>
                            </th>
                            <th className="th-bg-darkgreen1">
                                <div className="th-align-bt">Payment Amt</div>
                            </th>
                            <th className="th-bg-darkgreen1">
                                <div className="th-align-bt">Expected Date </div>
                            </th>
                            <th className="th-bg-darkgreen1">
                                <div className="th-align-bt">Under/Over Payment</div>
                            </th>
                            <th className="th-bg-darkblue1">
                                <div className="th-align-bt">Community Contribution was helpful </div>
                            </th>
                            <th className="th-bg-darkblue1">
                                <div className="th-align-bt">Community Contribution Indicator</div>
                            </th>
                            <th className="th-bg-darkblue1">
                                <div className="th-align-bt">Rated by Communinity</div>
                            </th>
                            <th className="th-bg-green1">
                                <div className="th-align-bt">All Clear</div>
                            </th>
                            <th className="th-bg-orange1">
                                <div className="th-align-bt">Low Reimbursement Action</div>
                            </th>
                            <th className="th-bg-orange1">
                                <div className="th-align-bt">Contract Negoitation</div>
                            </th>
                            <th className="th-bg-orange1">
                                <div className="th-align-bt">Buid this as Front-End Validation</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {elementValueData.map((item, index) => (
                            <tr key={index} style={isChrome ? { height: heightData[index] + 2 } : { height: heightData[index] }}>
                                <td className="td-val" id="CHANCES_DENIEL" >{ (item.CHANCES_DENIEL && item.CHANCES_DENIEL.COUNTRY) && item.CHANCES_DENIEL.COUNTRY }</td>
                                <td className="td-val" id="Hx_Trouble_Maker_Claim">{ (item.Hx_Trouble_Maker_Claim && item.Hx_Trouble_Maker_Claim.COUNTRY) && item.Hx_Trouble_Maker_Claim.COUNTRY }</td>
                                <td className="td-val" id="Chances_You_will_not_get_Paid">{ (item.Chances_You_will_not_get_Paid && item.Chances_You_will_not_get_Paid.COUNTRY) && item.Chances_You_will_not_get_Paid.COUNTRY }</td>
                                <td className="td-val" id="LAG_Time_since_DOS">{ (item.LAG_Time_since_DOS && item.LAG_Time_since_DOS.COUNTRY) && item.LAG_Time_since_DOS.COUNTRY }</td>
                                <td className="td-val" id="Days_to_UTF">{ (item.Days_to_UTF && item.Days_to_UTF.COUNTRY) && item.Days_to_UTF.COUNTRY }</td>
                                <td className="td-val" id="Hx_DSO_indicator">{ (item.Hx_DSO_indicator && item.Hx_DSO_indicator.COUNTRY) && item.Hx_DSO_indicator.COUNTRY }</td>
                                <td className="td-val" id="Hx_Avg_Payment_Lag_Since_DOS">{ (item.Hx_Avg_Payment_Lag_Since_DOS && item.Hx_Avg_Payment_Lag_Since_DOS.COUNTRY) && item.Hx_Avg_Payment_Lag_Since_DOS.COUNTRY }</td>
                                <td className="td-val" id="Material_Payer_Update_this_FY">{ (item.Material_Payer_Update_this_FY && item.Material_Payer_Update_this_FY.COUNTRY) && item.Material_Payer_Update_this_FY.COUNTRY }</td>
                                <td className="td-val" id="Avg_Payment_Days_in_Community_since_DOS">{ (item.Avg_Payment_Days_in_Community_since_DOS && item.Avg_Payment_Days_in_Community_since_DOS.COUNTRY) && item.Avg_Payment_Days_in_Community_since_DOS.COUNTRY }</td>
                                <td className="td-val" id="denials">denials</td>
                                <td className="td-val" id="low_reimb">low_reimb</td>
                                <td className="td-val" id="bundling">bundling</td>
                                <td className="td-val" id="payer_rating">payer_rating</td>
                                <td className="td-val" id="mr_request">mr_request</td>
                                <td className="td-val" id="appeal_percentage">appeal_percentage</td>
                                <td className="td-val" id="appeal_paid_percentage">appeal_paid_percentage</td>
                                <td className="td-val" id="Under_Payment">{ (item.Under_Payment && item.Under_Payment.COUNTRY) && item.Under_Payment.COUNTRY }</td>
                                <td className="td-val" id="withhold">{ (item.withhold && item.withhold.COUNTRY) && item.withhold.COUNTRY }</td>
                                <td className="td-val" id="Low_Reimbursement">{ (item.Low_Reimbursement && item.Low_Reimbursement.COUNTRY) && item.Low_Reimbursement.COUNTRY }</td>
                                <td className="td-val" id="Out_of_Network_Payment">{ (item.Out_of_Network_Payment && item.Out_of_Network_Payment.COUNTRY) && item.Out_of_Network_Payment.COUNTRY }</td>
                                <td className="td-val" id="Audit_Tracker">{ (item.Audit_Tracker && item.Audit_Tracker.COUNTRY) && item.Audit_Tracker.COUNTRY }</td>
                                <td className="td-val" id="Material_Payer_Update_this_FY2">{ (item.Audit_Tracker && item.Audit_Tracker.COUNTRY) && item.Audit_Tracker.COUNTRY }</td>
                                <td className="td-val" id="Payer_Specific1">{ (item.Payer_Specific1 && item.Payer_Specific1.COUNTRY) && item.Payer_Specific1.COUNTRY }</td>
                                <td className="td-val" id="Payer_Specific2">{ (item.Payer_Specific1 && item.Payer_Specific1.COUNTRY) && item.Payer_Specific1.COUNTRY }</td>
                                <td className="td-val" id="Payer_Specific3">{ (item.Payer_Specific1 && item.Payer_Specific1.COUNTRY) && item.Payer_Specific1.COUNTRY }</td>
                                <td className="td-val" id="denials2">{ (item.Bundling && item.Bundling.COUNTRY) && item.Bundling.COUNTRY }</td>
                                <td className="td-val" id="Bundling2">{ (item.Bundling && item.Bundling.COUNTRY) && item.Bundling.COUNTRY }</td>
                                <td className="td-val" id="MR_Request2">{ (item.MR_Request && item.MR_Request.COUNTRY) && item.MR_Request.COUNTRY }</td>
                                <td className="td-val" id="Appeal2">{ (item.appeal_Percentage && item.appeal_Percentage.COUNTRY) && item.appeal_Percentage.COUNTRY }</td>
                                <td className="td-val" id="Appeal_and_Paid2">{ (item.Appeal_and_Paid_Percentage && item.Appeal_and_Paid_Percentage.COUNTRY) && item.Appeal_and_Paid_Percentage.COUNTRY }</td>
                                <td className="td-val" id="National">{ (item.National && item.National.COUNTRY) && item.National.COUNTRY }</td>
                                <td className="td-val" id="State">{ (item.State && item.State.COUNTRY) && item.State.COUNTRY }</td>
                                <td className="td-val" id="Local">{ (item.Local && item.Local.COUNTRY) && item.Local.COUNTRY }</td>
                                <td className="td-val" id="Your_Practice">{ (item.Your_Practice && item.Your_Practice.COUNTRY) && item.Your_Practice.COUNTRY }</td>
                                <td className="td-val" id="Payment_Amt">{ (item.Payment_Amt && item.Payment_Amt.COUNTRY) && item.Payment_Amt.COUNTRY }</td>
                                <td className="td-val" id="Expected_Date">{ (item.Expected_Date && item.Expected_Date.COUNTRY) && item.Expected_Date.COUNTRY }</td>
                                <td className="td-val" id="Under_Over_Payment">{ (item.Under_Over_Payment && item.Under_Over_Payment.COUNTRY) && item.Under_Over_Payment.COUNTRY }</td>
                                <td className="td-val" id="Community_Contribution_was_helpful">{ (item.Community_Contribution_was_helpful && item.Community_Contribution_was_helpful.COUNTRY) && item.Community_Contribution_was_helpful.COUNTRY }</td>
                                <td className="td-val" id="Community_Contribution_indicator">{ (item.Community_Contribution_indicator && item.Community_Contribution_indicator.COUNTRY) && item.Community_Contribution_indicator.COUNTRY }</td>
                                <td className="td-val" id="Rated_by_Community">{ (item.Rated_by_Community && item.Rated_by_Community.COUNTRY) && item.Rated_by_Community.COUNTRY }</td>
                                <td className="td-val" id="All_Clear">{ (item.All_Clear && item.All_Clear.COUNTRY) && item.All_Clear.COUNTRY }</td>
                                <td className="td-val" id="Low_reimbursement_Action">{ (item.Low_reimbursement_Action && item.Low_reimbursement_Action.COUNTRY) && item.Low_reimbursement_Action.COUNTRY }</td>
                                <td className="td-val" id="Contract_Negoitation">{ (item.Contract_Negoitation && item.Contract_Negoitation.COUNTRY) && item.Contract_Negoitation.COUNTRY }</td>
                                <td className="td-val" id="Build_this_as_front_end_validation">{ (item.Build_this_as_front_end_validation && item.Build_this_as_front_end_validation.COUNTRY) && item.Build_this_as_front_end_validation.COUNTRY }</td>
                                
                            </tr>
                        ))}
                        
                    </tbody>
                </Table>
            </div>

            {showLoader && (
                <div className="loader-back" style={{height: containerHeight + 89 }} >

                    <div className='loader-style'>
                        {/* <RotatingLines
                            visible={true}
                            //   height="96"
                            width="96"
                            //   color="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            ariaLabel="rotating-lines-loading"
                            //   wrapperStyle={{}}
                            //   wrapperClass=""
                        /> */}

                        <MagnifyingGlass
                            visible={true}
                            height="120"
                            width="120"
                            ariaLabel="magnifying-glass-loading"
                            wrapperStyle={{}}
                            wrapperClass="magnifying-glass-wrapper"
                            glassColor="#c0efff"
                            color="#4fa94d"
                        />
                    </div>

                </div>
            )}

        </div>
    )
}

export default ClaimElements
