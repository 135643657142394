import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeartPulse, faCircleInfo, faEye, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { faMicrochip } from '@fortawesome/free-solid-svg-icons'
import { getHxCountData } from '../../../../resources/api-constants'
import { useDispatch, useSelector } from 'react-redux';
import { setHxElements } from '../../../../store/actions/hxElements';
import HxIcon from '../../../../assets/images/hx_icon.svg'
import '../../../../assets/styles/intelligence.scss'

interface ArtificialIntelligenceProps {
    hxData: any
}

const ArtificialIntelligence: React.FC<ArtificialIntelligenceProps> = ({ hxData }) => {
    
    const dispatch = useDispatch();
    const userDetails = useSelector((state: any) => state.userDetails);
    
    const [showLive, setshowLive] = useState(false)
    const [forumDetails, setForumDetails] = useState <any[]>()
    const [showNoMatch, setshowNoMatch] = useState(false)
    const [showLoading, setshowLoading] = useState(false)
    const [showInfo, setShowInfo] = useState(0)
    const [scrolled, setScrolled] = useState(false)

    let scrollList:any = '';
    let hasEvent = true;
    let totalHxData:any = [];
    let startCount = 0;
    let scrollLoad = false;

    useEffect(() => {

        setForumDetails([])

        if (userDetails.username !== '') {
            setshowLive(false)
            setshowNoMatch(false)
            setshowLoading(true)
            setScrolled(false)
            
            fetchHxCount(0).then(res => {
                // console.log(res);
                setForumDetails(res);
                totalHxData = res;

                if (hasEvent) {
                    setTimeout(() => {
                        // Add a scroll event listener
                        scrollList = document.getElementById('scrollList');
                        scrollList && scrollList.addEventListener('scroll', handleScroll);
                    }, 1000);
                }
                hasEvent = false

            }).catch(error => {
                console.error('Error fetching hxData:', error);
            });
        }

        if (scrollList) {
            // Clean up the event listener on component unmount
            return () => {
                scrollList.removeEventListener('scroll', handleScroll);
            };
        }

    }, [hxData, userDetails])

    const handleScroll = () => {
        const element = document.getElementById('scrollList');
        let scHeight = 0
        // element && (scHeight = element.scrollTop - (element.clientHeight * 2))
        element && (scHeight = (element.scrollTop))
        // Check if the scroll position is at the bottom
        if (!scrollLoad && element && element.scrollTop + element.clientHeight === element.scrollHeight) {

            setScrolled(true)
            setshowLoading(true)
            startCount += 50
            callFetchHxCount(startCount, scHeight);
            scrollLoad = true;
            // element.scrollTop = scHeight;
            
        }
    }

    const callFetchHxCount = (s: number, scHeight: number) => {
        fetchHxCount(s).then(res => {
            
            // let resultArr = forumDetails?.push(res)
            // console.log(res);
            // console.log(totalHxData);
            totalHxData = [...totalHxData, ...res]
            
            setForumDetails(totalHxData);
            const element = document.getElementById('scrollList');
            element && (element.scrollTop = scHeight)
            
            scrollLoad = false;
        }).catch(error => {
            console.log(error);  
        })
    }

    const fetchHxCount = async (st: number) => {
        
        const data = {
            // practice: userDetails.ps_account,
            practice: '2117',
            payerPkey: hxData.payerPkey,
            payerId: hxData.payerId,
            patAge: hxData.patAge,
            patGender: hxData.patGender,
            billingCode: hxData.billingCode,
            username: userDetails.username,
            start: st,
        }
        
        const res = await getHxCountData(data)
        if (res && res !== 'error') {
            
            setshowLoading(false)
            dispatch(setHxElements(res));
            if ("hx" in res) {
                setshowLive(true)
                setshowNoMatch(false)

                return res.hx

            } else {
                
                setshowNoMatch(true)
                setshowLive(false)
                return []
            }
            
        }
    }

    const handleShowHide = (id: any) => {
        if (showInfo === id) {
            setShowInfo(0)
        } else {
            setShowInfo(id)
        }
    }

    return (
        <div className="artificial-container">
            <div className="art-header">
                <h6 className="art-heading">
                    <FontAwesomeIcon icon={faMicrochip} className="notify-icon" />
                    Artificial Intelligence
                </h6>
            </div>

            {showLive && forumDetails !== undefined && (
                <div className='scroll-container' id='scrollList'>
                    {forumDetails.map((forum) => (
                        <div key={forum.HX_NAME} className="live-container">
                            <div className="live-data" onClick={() => {handleShowHide(forum.HX_NAME)}}>
                                <div className="live-icon">
                                    <div className="hx-icon-second">
                                        <img src={HxIcon} alt="paid.md logo" style={{ paddingTop: '3px' }} />
                                    </div>
                                </div>
                                <div className="live-body">
                                    <div className="live-body-head">{forum.HX_NAME}</div>
                                    <div className="live-body-desc">{forum.HX_DESC}</div>
                                    <div className="live-body-menu">
                                        <a
                                            className="small-text"
                                            
                                        >
                                            <FontAwesomeIcon icon={faCircleInfo} style={{ marginRight: '3px' }} />
                                            info
                                        </a>
                                        {/* <a
                                            className="small-text"
                                            // onClick={() => {
                                            //     navigateToElementTree(forum)
                                            // }}
                                        >
                                            <FontAwesomeIcon icon={faEye} style={{ marginRight: '3px' }} />
                                            View
                                        </a> */}
                                        {/* <a className="small-text">
                                            <FontAwesomeIcon icon={faThumbsUp} style={{ marginRight: '3px' }} />
                                            {forum.LIKES}
                                        </a> */}
                                    </div>
                                </div>
                                <div className="live-count">
                                    <span className="live-count-span">{forum.HX_COUNT}</span>
                                </div>
                            </div>
                            {/* {forum.HX_DESC && ( */}
                            <div className={`exp-container ${showInfo === forum.HX_NAME ? 'exp-show' : 'exp-hide'}`}>
                                
                                {/* {forum.HX_DESC.map((des: any, index: number) => ( */}
                                    {/* <div key={index}> */}
                                <div>
                                    <hr />
                                    <small className="exp-data">
                                        <strong>{forum.HX_NAME}</strong> - <span style={{ fontSize: '12px' }}>{forum.HX_DESC}</span>
                                    </small>
                                </div>
                                {/* ))} */}
                                
                            </div>
                            {/* )} */}
                        </div>
                    ))}
                </div>
            )}

            {showNoMatch && (
                <div className="no-records" style={{ textAlign: 'center', marginTop: '10px' }}>
                    No matching records found
                </div>
            )}

            {showLoading && (
                <div className={scrolled ? "live-loader-scroll" : "live-loader"}>
                    <div className='loader-wrapper'>
                        <div className="loaderlive-hx"></div>
                        <small>Loading ...</small>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ArtificialIntelligence
