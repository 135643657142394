import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeartPulse, faCircleInfo, faEye, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import LiveIcon from '../../../assets/images/live_icon.svg'
import { getPostCountData } from '../../../resources/api-constants'
import { useDispatch, useSelector } from 'react-redux';
import { setLiveElements } from '../../../store/actions/liveElements';
import { setForumValues } from '../../../store/actions/forumValues';
import { useNavigate } from 'react-router-dom';
import '../../../assets/styles/intelligence.scss'

interface CommunityIntelligenceProps {
    payerData: any
    reasonData: string | null
    forumData: any
}

const CommunityIntelligence: React.FC<CommunityIntelligenceProps> = ({ payerData, reasonData, forumData }) => {

    const navigate = useNavigate();

    const userDetails = useSelector((state: any) => state.userDetails);
    const dispatch = useDispatch();

    useEffect(() => {
        forumData !== '' && forumData !== null && fetchPostCount()
    }, [forumData, reasonData])

    const [showInfo, setShowInfo] = useState(0)
    const [showLive, setshowLive] = useState(false)
    const [showLoading, setshowLoading] = useState(false)
    const [showNoMatch, setshowNoMatch] = useState(false)
    const [forumDetails, setForumDetails] = useState <any[]>()

    const fetchPostCount = async () => {

        setshowLoading(true)
        const data = {
            forums: [] as string[],
            elements: reasonData,
            username: userDetails.username,
            start: 0,
        }
        data.forums = forumData
        
        const res = await getPostCountData(data)
        if (res !== 'error') {

            setshowLoading(false)
            if ("forums" in res) {
                setshowLive(true)
                setshowNoMatch(false)
                setForumDetails(res.forums)
            } else {
                setshowNoMatch(true)
                setshowLive(false)
            }
            dispatch(setLiveElements(res));
            
        }
    }

    const handleShowHide = (id: any) => {
        if (showInfo === id) {
            setShowInfo(0)
        } else {
            setShowInfo(id)
        }
    }

    const navigateToElementTree = (data: any) => {
        dispatch(setForumValues(data))
        navigate('/elementtree');
    }

    return (
        <div className="community-container">
            <div className="com-header">
                <h6 className="com-heading">
                    <FontAwesomeIcon icon={faHeartPulse} className="notify-icon" />
                    Live Community Intelligence
                </h6>
            </div>

            {showLive && forumDetails !== undefined && (
                <div className='scroll-container'>
                    {forumDetails.map((forum) => (
                        <div key={forum.FORUM_ID} className="live-container">
                            <div className="live-data" onClick={() => {handleShowHide(forum.FORUM_ID)}}>
                                <div className="live-icon">
                                    <div className="live-icon-second">
                                        <img src={LiveIcon} alt="paid.md logo" style={{ paddingTop: '3px' }} />
                                    </div>
                                </div>
                                <div className="live-body">
                                    <div className="live-body-head">{forum.FORUM_NAME}</div>
                                    <div className="live-body-desc">{forum.DESC[0]}</div>
                                    <div className="live-body-menu">
                                        <a
                                            className="small-text"
                                            
                                        >
                                            <FontAwesomeIcon icon={faCircleInfo} style={{ marginRight: '3px' }} />
                                            info
                                        </a>
                                        <a className="small-text" onClick={() => { navigateToElementTree(forum)}}>
                                            <FontAwesomeIcon icon={faEye} style={{ marginRight: '3px' }} />
                                            View
                                        </a>
                                        <a className="small-text">
                                            <FontAwesomeIcon icon={faThumbsUp} style={{ marginRight: '3px' }} />
                                            { forum.LIKES }
                                        </a>
                                    </div>
                                </div>
                                <div className="live-count">
                                    <span className="live-count-span">{forum.POSTS}</span>
                                </div>
                            </div>

                            <div className={`exp-container ${showInfo === forum.FORUM_ID ? 'exp-show' : 'exp-hide'}`}>
                                {forum.DESC.map((des: any, index: number) => (
                                    <div key={index}>
                                        
                                        <hr />
                                        <small className="exp-data">
                                            <strong>{ forum.FORUM_NAME.split(", ")[index] }</strong> -{' '}
                                            <span style={{ fontSize: '12px' }}>{des}</span>
                                        </small>
                                        
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {showNoMatch && (
                <div className="no-records" style={{ textAlign: 'center', marginTop: "10px" }}>
                    No matching records found
                </div>
            )}

            {showLoading &&(
                <div className="live-loader">
                    <div className='loader-wrapper'>
                        <div className="loaderlive-hx"></div>
                        <small>Loading ...</small>
                    </div>
                </div>
            )}
        </div>
    )
}

export default CommunityIntelligence
