import { createAction } from '@reduxjs/toolkit';

export const setForumValues = createAction<{ 
    DESC: string[];
    FORUM_DESC: string;
    FORUM_ID: number;
    FORUM_NAME: string;
    ICON_IMG_NAME: string;
    LIKES: number;
    NATIVE: number;
    POST: number;
    POSTS: number;
    REPLIES: number;
}>('forumValues/setForumValues');
