import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Login from './pages/Login'
import Home from './pages/home'
import ElementTree from './pages/elementtree'
import Claim from './pages/claim'
// import ArtificialIntelligence from './pages/Intelligence/ArtificialIntelligence'
// import CommunityIntelligence from './pages/Intelligence/CommunityIntelligence'
import NotFoundPage from './pages/NotFoundPage'
import Test from './pages/HomePage'
import { ROUTES } from './resources/routes-constants'
import './styles/main.sass'

const RootComponent: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="*" element={<NotFoundPage />} />
                <Route path={ROUTES.LOGIN_ROUTE} element={<Login />} />
                <Route path={ROUTES.HOME_ROUTE} element={<Home />} />
                <Route path={ROUTES.ELEMENT_TREE} element={<ElementTree />} />
                <Route path={ROUTES.CLAIM} element={<Claim />} />

                {/* <Route path={ROUTES.TEST} element={<Test />} /> */}
                
                {/* <Route path={ROUTES.COMMUNITY_INTELLIGENCE_ROUTE} element={<ArtificialIntelligence />} />
                <Route path={ROUTES.ARTIFICIAL_INTELLIGENCE_ROUTE} element={<CommunityIntelligence />} /> */}
            </Routes>
        </Router>
    )
}

export default RootComponent
