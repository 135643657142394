import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar'
import Breadcrumb from '../../components/Breadcrumbs'
import HxData from '../Intelligence/ArtificialIntelligence/hxData.tsx'
import AiData from '../Intelligence/ArtificialIntelligence/aiData.tsx'
import CommunityIntelligence from '../Intelligence/CommunityIntelligence'
import PostForm from '../form'
import { getInitialValues, getToken } from '../../resources/api-constants'
import { useSelector, useDispatch } from 'react-redux';
import { setLoginValues } from '../../store/actions/loginValues';
import { setUserDetails } from '../../store/actions/userDetails';
import { RotatingLines } from 'react-loader-spinner';
import '../../assets/styles/home.scss'


const Home: React.FC = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loginInfo = localStorage.getItem('_login_info')
    // const userDetails = useSelector((state: any) => state.userDetails);
    const loginVal = useSelector((state: any) => state.loginValues);

    const [payerData, setPayerData] = useState('');
    const [forumData, setForumData] = useState('');
    const [showLoader, setShowLoader] = useState(false)
    const [hxData, setHxData] = useState('');
    const [aiData, setAiData] = useState('');
    const [reasonData, setReasonData] = useState(null);
    const [billingData, setBillingData] = useState('');
    const [hxOrAi, setHxOrAi] = useState('hx')

    useEffect(() => {
        !loginInfo && navigate('/')
        // fetchInitialValues()
        fetchToken()
    }, [])

    const fetchToken = async() => {
        const res = await getToken({});
        if (res !== 'error') {
            dispatch(setLoginValues(res))
            localStorage.setItem('_login_info', JSON.stringify(res));
        }
        // console.log(res);
    }

    // const fetchInitialValues = async () => {
    //     const params = {

    //     }
    //     const res = await getInitialValues(params)
    //     dispatch(setUserDetails(res));
        
    // }

    const handlePostDataChange = (newData: any) => {
        setPayerData(newData);
    };

    const handleHxParamDataChange = (newdata: any) => {
        setHxData(newdata)
    }

    const handleAiParamDataChange = (newdata: any) => {
        setAiData(newdata)
    }

    const handleForumDataChange = (newData: any) => {
        setForumData(newData);
    };

    const handleReasonDataChange = (newData: any) => {
        setReasonData(newData);
    };

    const handleBillingCodeChange = (newData: any) => {
        setBillingData(newData);
    };

    const handleFoumCreation = (newData: boolean) => {
        setShowLoader(newData);
    }

    return (
        <>
            {loginInfo && (

                <>
                    <div className='home-body'>

                        <Navbar/>

                        <Breadcrumb payerData={payerData} billingData={billingData}/>

                        <div className='main-container'>
                            
                            <CommunityIntelligence forumData={forumData} payerData={payerData} reasonData={reasonData}/>

                            <PostForm onForumCreation={handleFoumCreation} onAiParamDataChange={handleAiParamDataChange} onHxParamDataChange={handleHxParamDataChange} onPayerDataChange={handlePostDataChange} onForumDataChange={handleForumDataChange} onReasonChange={handleReasonDataChange} onBillingCodeChange={handleBillingCodeChange}/>

                            {hxOrAi === 'hx' ? (
                                <HxData hxData={hxData}/>
                            ) : (
                                <AiData paramData={aiData}/>
                            )}

                        </div>

                    </div>

                    {showLoader && (
                        <div className="loader-back">

                            <div className='loader-style'>
                                <RotatingLines
                                    visible={true}
                                    //   height="96"
                                    width="96"
                                    //   color="grey"
                                    strokeWidth="5"
                                    animationDuration="0.75"
                                    ariaLabel="rotating-lines-loading"
                                    //   wrapperStyle={{}}
                                    //   wrapperClass=""
                                />
                            </div>

                        </div>
                    )}
                </>
            )}

        </>
    )
}

export default Home