import { createReducer } from '@reduxjs/toolkit';
import { setLoginValues } from '../actions/loginValues';

interface LoginValuesReducer {
    token: string; 
    sid: string; 
}

const initialState: LoginValuesReducer = {
    token: '',
    sid: '',
};

const loginValuesReducer = createReducer<LoginValuesReducer>(initialState, (builder) => {
    builder.addCase(setLoginValues, (state, action) => {
        state.token = action.payload.token;
        state.sid = action.payload.sid;
    });
});

export default loginValuesReducer;